import { useQueryClient, useMutation } from 'react-query'
import { QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteSelectedPastesByPasteKey } from '../../core/_requests'
import { useAppDispatch } from '../../../../../hooks'
import { pasteDeleted } from '../../../../../redux/pastesSlice'

const PastesListGrouping = () => {
  const { selected, clearSelected } = useListView()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()
  const dispatch = useAppDispatch()

  const deleteSelectedItems = useMutation(() => deleteSelectedPastesByPasteKey(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      dispatch(pasteDeleted)
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.PASTES_LIST}-${query}`])
      clearSelected()
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete Selected
      </button>
    </div>
  )
}

export { PastesListGrouping }
