import { createSlice } from '@reduxjs/toolkit'
import { UserRedactedModel } from '../modules/auth'
import { getUser, searchUsersRedactedByQuery } from '../modules/users/api/_userRequests'


interface RedactedUserState {
    users: UserRedactedModel[]
    status: 'idle' | 'loading' | 'failed' | 'succeeded' | 'fulfilled'
    error?: any
}

const initialState: RedactedUserState = {
    users: [],
    status: 'idle',
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        updateDisplayPicture(state, action) {
            const existingUser = state.users.find(user => user.id === action.payload.id)
            if (existingUser) {
                existingUser.userDisplayPictureUrl = action.payload.userDisplayPictureUrl
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched user to the array
                state.users = state.users.concat(action.payload)
            })
            .addCase(getUser.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(searchUsersRedactedByQuery.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(searchUsersRedactedByQuery.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched user to the array
                state.users = state.users.concat(action.payload)
            })
            .addCase(searchUsersRedactedByQuery.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }

})

export const { updateDisplayPicture } = usersSlice.actions

export const selectAllUsers = (state: { users: RedactedUserState }) => state.users.users

export const selectUserByUsername = (state: { users: RedactedUserState }, username) => state.users.users.find(user => user.username === username)

export const selectUsersByQuery = (state: { users: RedactedUserState }, query) => state.users.users.filter(user => user.username.includes(query))

export default usersSlice.reducer