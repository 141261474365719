import { FC, useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../modules/auth';
import { FeatureCard } from './FeatureCard';
import Typed from 'typed.js';
import { Helmet } from "react-helmet";
import { AdsenseBlock } from '../../modules/monetization/AdsenseBlock';

const LandingPage: FC = () => {
    const { currentUser } = useAuth();

    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate()

    // Create Ref element.
    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: [
                "Ever encounter situations where you noted down something important on a random device but have can't transfer the data back to yourself?",
                "When you want to share a file or text with your colleague but cannot remember the URL to the shared content?",
                "You received the file your friend sent you but has no way to preview the content online?",
            ], // Strings to display
            startDelay: 300,
            typeSpeed: 10,
            backSpeed: 10,
            backDelay: 3000,
            loop: true,
        });

        // Destroying
        return () => {
            typed.destroy();
        };
    }, []);

    function renderUserName() {
        if (!currentUser) {
            return 'Guest'
        }
        if (currentUser?.firstName || currentUser?.lastName) {
            return `${currentUser?.firstName} ${currentUser?.lastName}`
        }
        return currentUser?.username
    }

    function navigateToSearchPage() {
        navigate({
            pathname: '/search',
            search: '?search=' + searchQuery,
        })
        setSearchQuery('')
    }

    return (
        <>
            <Helmet defaultTitle="PasteThing - Share Your Knowledge with Others">
                <meta name="description"
                    content="PasteThing is a versatile SaaS enabling users to effortlessly share text, code, and files. It supports Markdown, syntax highlighting, and renders PDFs, Word documents, and Excel files for easy viewing and downloading."
                />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Navbar className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            alt=""
                            src={toAbsoluteUrl('/media/logos/default.svg')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                        />{' '}
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Brand href="https://blog.pastething.com" className='rounded' target="_blank" rel="noopener noreferrer">
                            <i className="fa-regular fa-pen-to-square fs-2 px-2 align-middle text-primary" />
                            <span className='fw-bold'>Blog</span>
                        </Navbar.Brand>
                        <Navbar.Text className='flex-sm-row'>
                            <form
                                data-kt-search-element='form'
                                className='d-flex align-items-center align-self-stretch w-100 px-3'
                                autoComplete='off'
                                onSubmit={navigateToSearchPage}
                            >
                                <KTIcon
                                    iconName='magnifier'
                                    className=' fs-2 text-gray-700 position-absolute top-50 translate-middle-y ms-4'
                                />
                                <input
                                    type='text'
                                    className='form-control bg-transparent ps-13 fs-7 flex-lg-row align-items-center justify-content-center'
                                    name='search'
                                    placeholder='Search for pastes...'
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </form>
                        </Navbar.Text>
                        <Navbar.Text className='d-md-flex'>
                            {currentUser ? (
                                <div>
                                    Signed in as : <Link to="/account/overview">{renderUserName()}</Link>
                                </div>
                            ) : (
                                <button
                                    className='btn btn-primary btn-sm'
                                    onClick={() => navigate("/auth/login")}
                                >
                                    Sign in
                                </button>
                            )}
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="d-flex flex-row justify-content-center">
                {/* main description of the website */}
                <Container>
                    <div className='row align-items-center'>
                        {/* image of the prototype of the product */}
                        <div className='col-lg-6 d-flex justify-content-center text-center'>
                            <img src={toAbsoluteUrl('/media/landing/main.png')} alt="" className='img-fluid' />
                        </div>
                        <div className='col-lg-6 py-6'>
                            <div className='d-flex flex-column align-items-center'>
                                <div className='text-black fw-bolder d-flex align-items-center justify-content-center min-h-90px mw-300px fs-3tx card-rounded px-5 py-3' style={{ backgroundColor: '#ffd600' }}>PasteThing</div>
                                <br />
                                <div className='d-flex flex-column align-items-center'>
                                    <div className='text-black fw-bolder d-flex align-items-center justify-content-center min-h-90px mw-550px fs-2x px-5 py-3 card-rounded text-center' style={{ backgroundColor: '#ffd600' }}>
                                        Send Texts and Files Conveniently
                                    </div>
                                    <br />
                                    <button className='btn btn-primary btn-lg fw-bold col-5' onClick={() => navigate('/create')}>
                                        <KTIcon iconName='plus' />
                                        Create Paste
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            <div className='my-9' />
            <div className='d-flex flex-row flex-center'>
                <Container className='col-7'>
                    {/* executive summary value addedness of product*/}
                    <div className='row align-items-center'>
                        <div className="d-flex row col-lg-5">
                            <span className='fw-bold fs-2x'>We solve your problem of transferring text and files across devices and people.</span>
                        </div>
                        <div className="d-flex row col-lg-7 py-1" style={{ height: "100px" }}>
                            <span className='fs-2' ref={el}></span>
                        </div>
                    </div>
                </Container>
            </div>
            <div className='my-20'></div>
            <div className="d-flex flex-row justify-content-center align-items-center">
                <Container>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 py-6 text-center text-lg-start'>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <div className='text-black fw-bold d-flex  min-h-90px mw-300px fs-3tx card-rounded px-5 py-3' style={{ backgroundColor: '#ffd600' }}>View PDF Documents</div>
                                <br />
                                <div className='d-flex flex-column align-items-start'>
                                    <div className='text-black d-flex min-h-90px mw-500px fs-2x px-5 py-3 card-rounded' style={{ backgroundColor: '#ffd600' }}>
                                        Instantly view and share PDFs online for work or personal projects
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* image of the prototype of the product */}
                        <div className='col-lg-6 d-flex justify-content-center text-center'>
                            <img src={toAbsoluteUrl('/media/landing/pdf-preview.jpg')} alt="" className='img-fluid' />
                        </div>
                    </div>
                </Container>
            </div>
            <div className='my-18'></div>
            <div className="d-flex flex-row justify-content-center justify-content-md-center justify-content-sm-center">
                {/* main description of the website */}
                <Container>
                    <div className='row align-items-center'>
                        {/* image of the prototype of the product */}
                        <div className='col-lg-6 d-flex justify-content-center justify-content-sm-center text-center'>
                            <img src={toAbsoluteUrl('/media/landing/code-preview.jpg')} alt="" className='img-fluid' />
                        </div>
                        <div className='col-lg-6 py-6 text-center'>
                            <div className='d-flex flex-column align-items-center'>
                                <div className='text-black fw-bold d-flex min-h-90px mw-350px fs-3tx card-rounded px-5 py-3' style={{ backgroundColor: '#ffd600' }}>Code Syntax Highlighting</div>
                                <br />
                                <div className='d-flex flex-column align-items-start'>
                                    <div className='text-black d-flex min-h-90px mw-500px fs-2x px-5 py-3 card-rounded' style={{ backgroundColor: '#ffd600' }}>
                                        Share Your Code Easily and Read Them With Code Syntax Highlighting
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className='my-18'></div>
            <div className='d-flex flex-row flex-center'>
                <div className='row gy-5 g-xl-10 col-10'>
                    <div className='col-xl-3 col-md-6'>
                        <FeatureCard
                            backGroundColor='#F7D8BA'
                            title='Effortless Text Pasting'
                            description={"PasteThing's intuitive interface makes text pasting a breeze, while Markdown support lets you format and stylize your content effortlessly."}
                            icon='clipboard'
                        />
                    </div>
                    <div className='col-xl-3 col-md-6'>
                        <FeatureCard
                            backGroundColor='#E1F8DC'
                            title='Personalized URLs'
                            description={"Craft your own unique URL for each paste, making it easy to share and remember. Customize your links and leave a lasting impression on your audience."}
                            icon='user-square'
                        />
                    </div>
                    <div className='col-xl-3 col-md-6'>
                        <FeatureCard
                            backGroundColor='#ACDDDE'
                            title='Seamless File Rendering'
                            description={"View popular file types such as PDFs, JPGs and PNGs directly on our website."}
                            icon='file'
                        />
                    </div>
                    <div className='col-xl-3 col-md-6'>
                        <FeatureCard
                            backGroundColor='#FEF8DD'
                            title='Formatting Magic'
                            description={"With Markdown and built-in code syntax highlighting support, your texts will come alive, making them easier to read and understand."}
                            icon='code'
                        />
                    </div>
                </div>
            </div>
            <div className='divider my-18'></div>
            <div className='d-flex flex-row flex-center'>
                <Container>
                    <div className='row text-center align-middle'>
                        <span className='fs-3tx pb-12'>
                            Elevate your productivity with PasteThing.
                        </span>
                        <button className='btn btn-primary btn-lg fw-bold mx-auto col-xl-2 col-lg-3 col-md-3 col-sm-3' onClick={() => navigate('/create')}>
                            <KTIcon iconName='plus' />
                            Create Paste
                        </button>
                    </div>
                </Container>
            </div>
            <div className='divider my-18'></div>
            <div className='d-flex flex-row flex-center'>
                <Container>
                    <AdsenseBlock className="row text-center align-middle" />
                </Container>
            </div>
            {/* company info */}
            <div className='d-flex flex-row flex-center bg-black py-12'>
                <Container className='col-7'>
                    <div className='row text-white pb-20 flex-column justify-content-center'>
                        <div className='col py-3'>
                            <img
                                alt=""
                                src={toAbsoluteUrl('/media/logos/default.svg')}
                                width="60"
                                height="60"
                                className="d-inline-block"
                            />{' '}
                            <span className='fw-bolder fs-2x'>PasteThing</span>
                            <div className='py-3'>Don't be shy, get in touch with us and share interesting things with the world!</div>
                            <div className='row justify-content-start align-items-start'>
                                <a href='https://www.facebook.com/pastething' target="_blank" rel="noopener noreferrer" className='col-auto'>
                                    <KTIcon iconName='facebook' className='fs-2x ' />
                                </a>
                                <a href='https://www.twitter.com/pastething' target="_blank" rel="noopener noreferrer" className='col-auto'>
                                    <KTIcon iconName='twitter' className='fs-2x' />
                                </a>
                            </div>
                        </div>
                        <div className='col py-3'>
                            <span className='fw-bolder fs-2x'>Contact Us</span>
                            <br />
                            <span className='fs-7'>Email Address: <a href={`mailto:contact@pastething.com`}>contact@pastething.com</a></span>
                        </div>
                    </div>
                    <div className='row text-white text-muted pt-20 align-items-start'>
                        <div className='col-6 col-md-auto'>
                            <Link to="/about" className='flex-column'>About Us</Link>
                        </div>
                        <div className='col-6 col-md-auto'>
                            <Link to="/faq" className='flex-column'>FAQ</Link>
                        </div>
                        <div className='col-6 col-md-auto'>
                            <Link to="/terms" className='flex-column'>Terms of Use</Link>
                        </div>
                        <div className='col-6 col-md-auto'>
                            <Link to="/privacy" className='flex-column'>Privacy Policy</Link>
                        </div>
                        <div className='col-6 col-md-auto'>
                            <Link to="/dmca" className='flex-column'>DMCA</Link>
                        </div>
                        <div className='col-6 col-md-auto'>
                            <Link to="/abuse" className='flex-column'>Report Abuse</Link>
                        </div>
                        <div className='col-6 col-md-auto'>
                            <Link to="https://blog.pastething.com" className='flex-column' target="_blank" rel="noopener noreferrer">Blog</Link>
                        </div>
                    </div>
                    <div className='row text-white text-muted py-1 align-items-start'>
                        <div className='col'>
                            Made by Sliverware
                        </div>
                        <div className='col'>
                            <div className='d-flex justify-content-end'>
                                All Rights Reserved
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

        </>
    );
};
export { LandingPage };