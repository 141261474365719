import { MarkdownViewer } from './MarkdownViewer'

const MarkdownViewerWrapper = (props) => {

    return (
        <div>
            <div className="card-header" >
                <h3 className="card-title">Markdown Formatted Text</h3>
                <div className="cursor-pointer collapsible rotate" data-bs-toggle="collapse" data-bs-target="#kt_docs_card_collapsible_markdown">
                    <div className="card-toolbar rotate-180">
                        <i className="ki-duotone ki-down fs-1"></i>
                    </div>
                </div>
            </div>
            <div id="kt_docs_card_collapsible_markdown" className="collapse show">
                <MarkdownViewer content={props.content} formik={props.formik} />
            </div>

            <div>
            </div>
        </div>
    )
}

export { MarkdownViewerWrapper }