import { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { useAuth } from '../../modules/auth/core/Auth';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getPasteByPasteKey, updatePasteLikesDislikes } from '../../modules/pastes/api/_pasteRequests';
import { useParams } from 'react-router-dom';
import { selectPasteByPasteKey } from '../../redux/pastesSlice';
import { PasteViewCard } from '../../modules/pastes/components/PasteViewCard';
import { PasteLikeCard } from '../../modules/pastes/components/PasteLikeCard';
import { PasteDislikeCard } from '../../modules/pastes/components/PasteDislikeCard';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { IUpdateLikeDislikePaste, Paste } from '../../modules/pastes/PasteModels';
import { CarouselViewerWrapper } from '../../modules/pastes/viewers/CarouselViewerWrapper';
import { PasteNotFoundCard } from '../../modules/pastes/components/PasteNotFoundCard';
import { RedirectToAuthModal } from '../../modules/auth/components/RedirectToAuthModal';

import { CommentsWrapper } from '../../modules/comments/CommentsWrapper';
import { IGetUser } from '../../modules/users/UserModel';
import { getUser } from '../../modules/users/api/_userRequests';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from "react-helmet";
import { FileViewerWrapper } from '../../modules/pastes/viewers/FileViewerWrapper';
import { PasteMetadataViewerWrapper } from '../../modules/pastes/viewers/PasteMetadataViewerWrapper';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';

enum PasteCardType {
    VIEW = 'view',
    LIKE = 'like',
    DISLIKE = 'dislike',
}

const ViewPastePage: FC<{ paste: Paste | undefined }> = ({ paste }) => {
    const { currentUser } = useAuth();
    const currentUserId = currentUser?.id;
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
    const [likedPaste, setLikedPaste] = useState<boolean>(false);
    const [dislikedPaste, setDislikedPaste] = useState<boolean>(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        if (paste && currentUserId) {
            paste.likedUsers.includes(currentUserId) ? setLikedPaste(true) : setLikedPaste(false);
            paste.dislikedUsers.includes(currentUserId) ? setDislikedPaste(true) : setDislikedPaste(false);
        }
    }, [paste, currentUserId])

    const handleReCaptcha = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('updateLikesDislikes')
            return token
        }
    };

    const dispatch = useAppDispatch()

    const handleLikesOnClick = async () => {
        if (!currentUser) {
            setShowLoginModal(true);
            return;
        }
        const token = await handleReCaptcha();

        const addLike = likedPaste ? -1 : 1;
        const params = {
            pasteKey: paste?.pasteKey,
            addLikeCount: addLike,
            reCaptchaToken: token ? token : '',
        } as IUpdateLikeDislikePaste

        await dispatch(updatePasteLikesDislikes(params)).unwrap()
            .then((res) => {
                setLikedPaste(!likedPaste);
            }).catch((error) => {
                // console.log(error);
            });

    }

    const handleDisikesOnClick = async () => {
        if (!currentUser) {
            setShowLoginModal(true);
            return;
        }

        const token = await handleReCaptcha();

        const addDislikes = dislikedPaste ? -1 : 1;
        const params = {
            pasteKey: paste?.pasteKey,
            addDislikeCount: addDislikes,
            reCaptchaToken: token ? token : '',
        } as IUpdateLikeDislikePaste

        await dispatch(updatePasteLikesDislikes(params)).unwrap()
            .then((res) => {
                setDislikedPaste(!dislikedPaste);
            }).catch((error) => {
                // console.log(error.response.message);
            });

    }

    function renderCard(type: PasteCardType, backGroundColor: string, value: number) {
        if (paste) {
            switch (type) {
                case PasteCardType.VIEW:
                    return <PasteViewCard className='card-xl-stretch mb-xl-10' backGroundColor={backGroundColor} views={value} />
                case PasteCardType.LIKE:
                    return <PasteLikeCard
                        className='card-xl-stretch mb-xl-10'
                        backGroundColor={backGroundColor}
                        likes={value}
                        handleOnClick={handleLikesOnClick}
                        likedPaste={likedPaste}
                    />
                case PasteCardType.DISLIKE:
                    return <PasteDislikeCard
                        className='card-xl-stretch mb-xl-10'
                        backGroundColor={backGroundColor}
                        dislikes={value}
                        handleOnClick={handleDisikesOnClick}
                        dislikedPaste={dislikedPaste}
                    />
            }
        }
        else {
            return <Skeleton count={9} />
        }
    }



    return (
        <>
            <div className='row gy-3 g-xl-10'>
                {/*begin::View Card*/}
                <div className='col-xl-4'>
                    {renderCard(PasteCardType.VIEW, '#FFF9CC', paste?.viewCount ? paste?.viewCount : 0)}
                </div>
                {/*end::View Card*/}

                {/*begin::Like Card*/}
                <div className='col-xl-4'>
                    {renderCard(PasteCardType.LIKE, '#F7D9E3', paste?.likeCount ? paste?.likeCount : 0)}
                </div>
                {/*end::Like Card*/}

                {/*begin::Like Card*/}
                <div className='col-xl-4'>
                    {renderCard(PasteCardType.DISLIKE, '#CCE6FF', paste?.dislikeCount ? paste?.dislikeCount : 0)}
                </div>
                {/*end::Like Card*/}
            </div>
            {/*end::Row*/}
            <div className='divider my-3'></div>
            <div className='row gy-3 g-xl-10'>
                <CarouselViewerWrapper paste={paste} />
            </div>
            <div className='divider my-3'></div>
            <div className='row gy-3 g-xl-10'>
                <FileViewerWrapper paste={paste} />
            </div>
            <div className='divider my-6'></div>
            <div className='row gy-3 g-xl-10'>
                <PasteMetadataViewerWrapper paste={paste} />
            </div>
            <div className='divider my-6'></div>
            <div className='row'>
                <CommentsWrapper pasteKey={paste?.pasteKey} />
            </div>
            <RedirectToAuthModal
                show={showLoginModal}
                onHide={() => setShowLoginModal(false)}
                title="Attention: Members-Exclusive Paste Zone"
                message="Unlock the feature of private pastes. Don't miss out! Create an account or log in to explore this exclusive feature."
            />
        </>
    );
};

const ViewPasteWrapper: FC = () => {
    const { pasteKey } = useParams();

    const dispatch = useAppDispatch();
    const paste = useAppSelector((state) => selectPasteByPasteKey(state, pasteKey));
    const [pasteNotFound, setPasteNotFound] = useState<boolean>(false);
    const { currentUser } = useAuth();
    const [pasteCreatorUsername, setPasteCreatorUsername] = useState<string>('');

    const getPasteCreatorProfile = async (createdBy) => {
        const userReq = {
            userId: createdBy,
        } as IGetUser

        if (createdBy) {
            await dispatch(getUser(userReq)).unwrap()
                .then((res) => {
                    setPasteCreatorUsername(res.username);
                }).catch((error) => {
                    // console.error(error);
                })
        }
    }

    useEffect(() => {
        if (pasteKey) {
            dispatch(getPasteByPasteKey(pasteKey)).unwrap()
                .then(async (res) => {
                    if (res) {
                        setPasteNotFound(false);
                        // private paste only accessible to creator
                        if (res.visibility === 'private' && currentUser?.id !== res.createdBy) {
                            setPasteNotFound(true);
                        }
                        else {
                            await getPasteCreatorProfile(res.createdBy);
                        }
                    }
                }).catch((err) => {
                    setPasteNotFound(true);
                });
        }
    }, [pasteKey]);
    dayjs.extend(relativeTime);
    return (
        <>
            {/* page description display created by who */}
            <PageTitle description={`Created by ${pasteCreatorUsername ? pasteCreatorUsername : 'guest'} ${dayjs(paste?.createdDate).fromNow()}`} breadcrumbs={[]}>
                {/* display paste title */}
                {paste?.title}
            </PageTitle>

            {pasteNotFound ? <PasteNotFoundCard /> : (
                <div>
                    <Helmet defaultTitle="PasteThing - Share Your Knowledge with Others">
                        <title>{`PasteThing - ${paste?.title}`}</title>
                        <meta name="description" content={paste?.content.slice(0, 150)} />
                        {paste?.visibility === 'public' ? <meta name="robots" content="index, nofollow" /> : null}
                    </Helmet>
                    <ViewPastePage paste={paste} />
                </div>
            )}
        </>
    );
};

export default ViewPasteWrapper;
