/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deletePasteByPasteKey } from '../../core/_requests'
import { Link, useLocation } from 'react-router-dom'
import { pasteDeleted } from '../../../../../redux/pastesSlice'
import { useAppDispatch } from '../../../../../hooks'

type Props = {
  pasteKey: string
}

const PasteActionsCell: FC<Props> = ({ pasteKey }) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()

  const location = useLocation()

  const showEditDelete = () => {
    if (location.pathname === "/account/pastes") {
      return true
    }
    return false
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // const openEditModal = () => {
  //   setItemIdForUpdate(id)
  // }

  const deletePaste = useMutation(() => deletePasteByPasteKey(pasteKey), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      dispatch(pasteDeleted)
      queryClient.invalidateQueries([`${QUERIES.PASTES_LIST}-${query}`])
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm px-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-100px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link to={"/p/" + pasteKey} className='menu-link px-3' >
            View
          </Link>
        </div>
        {/* end::Menu item */}

        {/* only show edit and delete buttons if the user is on the account/pastes page */}
        {showEditDelete() && <div>
          <div className='menu-item px-3'>
            <Link to={"/p/" + pasteKey + "/edit"} className='menu-link px-3' >
              Edit
            </Link>
          </div>

          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => await deletePaste.mutateAsync()}
            >
              Delete
            </a>
          </div>
        </div>
        }
      </div>
      {/* end::Menu */}
    </>
  )
}

export { PasteActionsCell }
