/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import { getPastesByCreatedBy } from './_requests'
import { useQueryRequest } from './QueryRequestProvider'
import { Paste } from '../../PasteModels'
import { useAuth } from '../../../auth/core/Auth'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../hooks'
import { selectUserByUsername } from '../../../../redux/usersSlice'
import { UserRedactedModel } from '../../../auth'

const QueryResponseContext = createResponseContext<Paste>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  const location = useLocation()
  const { username } = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const user = useAppSelector(state => selectUserByUsername(state, username)) as UserRedactedModel

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PASTES_LIST}-${query}`,
    () => {
      if (location.pathname === '/account/pastes') {
        // show private pastes
        return getPastesByCreatedBy(currentUser?.id || '', true, query)
      } else {
        if (!user) {
          navigate(`/user/${username}/overview`)
          return
        }
        return getPastesByCreatedBy(user?.id || '', false, query)
      }
    },
    { cacheTime: 9000, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
