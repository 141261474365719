// @ts-nocheck
import { Column } from 'react-table'
import { PasteInfoCell } from './PasteInfoCell'
import { PasteDateCell } from './PasteDateCell'
import { PasteActionsCell } from './PasteActionsCell'
import { PasteSelectionCell } from './PasteSelectionCell'
import { PasteCustomHeader } from './PasteCustomHeader'
import { PasteSelectionHeader } from './PasteSelectionHeader'
import { Paste } from '../../../PasteModels'
import { PastePasteKeyCell } from './PastePasteKeyCell'
import { PasteCountCell } from './PasteCountCell'
import { PasteVisibilityCell } from './PasteVisibilityCell'

const pastesColumns: ReadonlyArray<Column<Paste>> = [
  {
    Header: (props) => <PasteSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <PasteSelectionCell id={props.data[props.row.index].pasteKey} />,
  },
  {
    // paste title and content preview?
    Header: (props) => <PasteCustomHeader tableProps={props} title='Title' className='min-w-125px' />,
    id: 'title',
    Cell: ({ ...props }) => <PasteInfoCell paste={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <PasteCustomHeader tableProps={props} title='Paste Unique ID' className='min-w-100px' />,
    Cell: ({ ...props }) => <PastePasteKeyCell pasteKey={props.data[props.row.index].pasteKey} />,
    id: 'pasteKey'
  },
  {
    Header: (props) =>
      <PasteCustomHeader tableProps={props} title='Visibility' className='min-w-100px' />,
    Cell: ({ ...props }) => <PasteVisibilityCell visibility={props.data[props.row.index].visibility} />,
    id: 'visibility'
  },
  {
    Header: (props) => (
      <PasteCustomHeader tableProps={props} title='Expiration Date' className='min-w-100px' />
    ),
    id: 'expirationDate',
    Cell: ({ ...props }) => <PasteDateCell date={props.data[props.row.index].expirationDate} />,
  },
  {
    Header: (props) => (
      <PasteCustomHeader tableProps={props} title='View Count' className='min-w-50px' />
    ),
    Cell: ({ ...props }) => <PasteCountCell statusColor='info' count={props.data[props.row.index].viewCount} />,
    id: 'viewCount'
  },
  {
    Header: (props) => (
      <PasteCustomHeader tableProps={props} title='Like Count' className='min-w-50px' />
    ),
    Cell: ({ ...props }) => <PasteCountCell statusColor='danger' count={props.data[props.row.index].likeCount} />,
    id: 'likeCount'
  },
  {
    Header: (props) => (
      <PasteCustomHeader tableProps={props} title='Dislike Count' className='min-w-50px' />
    ),
    Cell: ({ ...props }) => <PasteCountCell statusColor='primary' count={props.data[props.row.index].dislikeCount} />,
    id: 'dislikeCount'
  },
  {
    Header: (props) => (
      <PasteCustomHeader tableProps={props} title='Created Date' className='min-w-100px' />
    ),
    id: 'createdDate',
    Cell: ({ ...props }) => <PasteDateCell date={props.data[props.row.index].createdDate} />,
  },
  {
    Header: (props) => (
      <PasteCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <PasteActionsCell pasteKey={props.data[props.row.index].pasteKey} />,
  },
]

export { pastesColumns }
