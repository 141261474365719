import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from '../core/_authRequests'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { handleFormErrors } from '../../errors/errorHelpers'
import { IResetPassword } from '../core/_models'
import { toast } from 'react-toastify'

const initialValues = {
    password: '',
    changepassword: '',
    reCaptchaToken: '',
}

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Minimum 8 character')
        .max(66, 'Maximum 66 characters')
        .required('Password is required'),
    changepassword: Yup.string()
        .min(8, 'Minimum 8 character')
        .max(66, 'Maximum 66 characters')
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function ResetPassword() {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [searchParams] = useSearchParams();

    const handleReCaptcha = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('forgotPassword')
            return token
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)

            const token = await handleReCaptcha();
            values.reCaptchaToken = token ? token : '';

            const params = {
                resetPasswordCode: searchParams.get('code'),
                newPassword: values.password,
                reCaptchaToken: values.reCaptchaToken
            } as IResetPassword

            await resetPassword(params)
                .then(() => {
                    setHasErrors(false)
                    setLoading(false)
                    toast.success('Password reset successfully. Please login with your new password.')
                })
                .catch((error) => {
                    // console.log(error.response.data.message)
                    setHasErrors(true)
                    setLoading(false)
                    setSubmitting(false)
                    handleFormErrors(error, setStatus)
                })
        },
    })

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    Please enter your new password to reset your password.
                </div>
                {/* end::Link */}
            </div>

            {/* begin::Title */}
            {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        Sorry, looks like there are some errors detected, please try again.
                    </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>
                        Your password has been reset successfully. Please login with your new password.
                    </div>
                </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
                <input
                    type='password'
                    placeholder='P@ssword1'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.password && formik.errors.password },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
                <input
                    type='password'
                    placeholder='P@ssword1'
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.changepassword && formik.errors.changepassword },
                        {
                            'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                        }
                    )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.changepassword}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                    <span className='indicator-label'>Submit</span>
                    {loading && (
                        <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        Cancel
                    </button>
                </Link>{' '}
            </div>
            {/* end::Form group */}
        </form>
    )
}
