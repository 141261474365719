import data from './markdown-cheatsheet.json'
import { SubMarkdownFormattingGuideCard } from './SubMarkdownFormattingGuideCard'

const MarkdownFormattingGuideCard = () => {

    const basicSyntax = data.cheat_sheet[0]?.basic_syntax || [];
    const extendedSyntax = data.cheat_sheet[1]?.extended_syntax || [];
    const loadedCheatSheets = [...basicSyntax, ...extendedSyntax];

    return (
        <div className="card rounded shadow-sm">
            <div className="card-header collapsible cursor-pointer rotate"
                data-bs-toggle="collapse"
                data-bs-target="#ph_markdown_highlight_guide_card"
            >
                <h3 className="card-title">Markdown Formatting</h3>
                <div className="card-toolbar rotate-180">
                    <i className="ki-duotone ki-down fs-1"></i>
                </div>
            </div>
            <div id="ph_markdown_highlight_guide_card" className="collapse show">
                <div className="card-body">
                    <div>
                        {loadedCheatSheets?.map(({ element, syntax }) => {
                            return (
                                <SubMarkdownFormattingGuideCard key={element} element={element} syntax={syntax} />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export { MarkdownFormattingGuideCard }
