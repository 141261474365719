import { FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Paste } from "../PasteModels";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PasteMetadataCard } from "../components/PasteMetadataCard";
import { PasteThingInfoCard } from "../components/PasteThingInfoCard";
import clsx from "clsx";

type Props = {
    paste: Paste | undefined;
};

const PasteMetadataViewerWrapper: FC<Props> = ({ paste }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return <Skeleton count={15} />;
    }

    return (
        <>
            <div className="card card-flush shadow-sm">
                <div className="card-header">
                    <div className="card-title"><img src={toAbsoluteUrl('/media/logos/default.svg')} alt="" className="h-30px w-30px" /></div>
                    <div className="card-toolbar"></div>
                </div>
                <div className="card-body pt-0 pb-6">
                    <div className="row">
                        <div className={clsx(
                            {
                                'col-sm-12 col-md-8': paste?.uploadedFileSizeInBytes && paste?.uploadedFileSizeInBytes > 0,
                                'col-sm-12 col-md-12': !(paste?.uploadedFileSizeInBytes && paste?.uploadedFileSizeInBytes > 0)
                            }
                        )}>
                            <PasteMetadataCard paste={paste} />
                        </div>
                        {paste?.uploadedFileSizeInBytes && paste?.uploadedFileSizeInBytes > 0 ? (
                            <div className="col-sm-12 col-md-4">
                                <PasteThingInfoCard paste={paste} />
                            </div>) : null
                        }
                    </div>

                </div>
            </div>
        </>
    );
};

export { PasteMetadataViewerWrapper };
