/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useEffect, useRef, useState } from 'react'
import { SearchComponent } from '../../../assets/ts/components'
import { KTIcon } from '../../../helpers'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { searchPastesByQuery } from '../../../../app/modules/pastes/api/_pasteRequests'
import { IQueryPaste, Paste } from '../../../../app/modules/pastes/PasteModels'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { searchUsersRedactedByQuery } from '../../../../app/modules/users/api/_userRequests'
import { UserRedactedModel } from '../../../../app/modules/auth'
import { selectRecentPastes } from '../../../../app/redux/pastesSlice'
import { UserProfilePicture } from '../../../../app/modules/profile/UserProfilePicture'
import { downloadFile } from '../../../../app/modules/auth/core/_authRequests'

type Props = {
  className?: string
  mobileToggleBtnClass?: string
}
const Search: FC<Props> = ({ className = '', mobileToggleBtnClass = '' }) => {
  const [menuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)

  const [pastes, setPastes] = useState<Paste[]>([])
  const [users, setUsers] = useState<UserRedactedModel[]>([])
  const [hideSearch, setHideSearch] = useState(false);
  const [loadingPastes, setLoadingPastes] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const location = useLocation()

  const suggestionPastes = useAppSelector(selectRecentPastes)

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  function navigateToSearchPage() {
    navigate('/search')
  }

  const process = (search: SearchComponent) => {
    // Hide recently viewed
    suggestionsElement.current!.classList.add('d-none')

    const query = search.inputElement.value

    function showResults() {
      // Show results
      resultsElement.current!.classList.remove('d-none')
      // Hide empty message
      emptyElement.current!.classList.add('d-none')
      // Hide recently viewed
      suggestionsElement.current!.classList.add('d-none')
    }

    function showNothing() {
      // Hide results
      resultsElement.current!.classList.add('d-none')
      // Show empty message
      emptyElement.current!.classList.remove('d-none')
    }

    const resultsElementQuery = {
      query: query,
      page: 1,
      size: 5,
      showPrivate: false
    } as IQueryPaste

    setLoadingPastes(true);
    setLoadingUsers(true);

    interface PastesResponse {
      pastes: Paste[];
    }
    setTimeout(function () {
      dispatch(searchPastesByQuery(resultsElementQuery))
        .then((res) => {
          const apiResponse = res.payload as PastesResponse;
          setPastes(apiResponse.pastes);
          showResults();
        })
        .catch((error) => {
          showNothing();
        })
        .finally(() => {
          setLoadingPastes(false);
          search.complete();
        });

      dispatch(searchUsersRedactedByQuery(query))
        .then((res) => {
          const queriedUsers = res.payload as UserRedactedModel[];

          // Create an array to store the promises of downloading profile pictures
          const downloadPromises = queriedUsers.map(async (user) => {
            const fileKey = 'user-dp/' + user?.id + '-dp';
            try {
              if (user.hasDisplayPicture) {
                const arr = await downloadFile(fileKey);
                const url = URL.createObjectURL(arr.data);
                const updatedUser = { ...user, userDisplayPictureUrl: url }; // Create a new object with the updated property
                return updatedUser;
              }
              return user;
            } catch (err) {
              // fail silently
              return user;
            }
          }) as Promise<UserRedactedModel>[];

          Promise.all<UserRedactedModel>(downloadPromises)
            .then((updatedUsers) => {
              // Once all profile pictures are downloaded and user objects are updated,
              // you can set the state with the filtered users array
              setUsers(updatedUsers);
              showResults();
            })
            .catch((error) => {
              // Handle the error if needed
              showNothing();
            })
            .finally(() => {
              setLoadingUsers(false);
              search.complete();
            });

        })
        .catch((error) => {
          showNothing();
        })
        .finally(() => {
          setLoadingUsers(false);
          search.complete();
        });

    }, 1500)


  }

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    suggestionsElement.current!.classList.remove('d-none')
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
    setPastes([])
    setUsers([])
  }

  const suggestionsElementQuery = {
    query: '',
    page: 1,
    size: 5
  } as IQueryPaste

  useEffect(() => {
    if (location.pathname === '/search') {
      setHideSearch(true);
    } else {
      setHideSearch(false);
    }

    // init suggestionsElement
    dispatch(searchPastesByQuery(suggestionsElementQuery))

    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', process)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)

  }, [location.pathname])

  return (
    <div
      id='kt_header_search'
      className={clsx('d-flex align-items-center', className)}
      data-kt-search-keypress='true'
      data-kt-search-min-length='2'
      data-kt-search-enter='enter'
      data-kt-search-layout='menu'
      data-kt-search-responsive='lg'
      data-kt-menu-trigger='auto'
      data-kt-menu-permanent='true'
      data-kt-menu-placement='bottom-end'
      data-kt-search='true'
      ref={element}
    >
      <div className={clsx('d-flex d-lg-none align-items-center')} data-kt-search-element='toggle'>
        <div className={mobileToggleBtnClass}>
          <KTIcon iconName='magnifier' className='fs-1' />
        </div>
      </div>

      <form
        data-kt-search-element='form'
        className='d-none align-items-center d-lg-flex w-100 mb-5 mb-lg-0 position-relative'
        autoComplete='off'
        onSubmit={navigateToSearchPage}
      >
        <KTIcon
          iconName='magnifier'
          className=' fs-2 text-gray-700 position-absolute top-50 translate-middle-y ms-4'
        />

        <input
          type='text'
          className='form-control bg-transparent ps-13 fs-7 h-40px'
          name='search'
          placeholder='Quick Search'
          data-kt-search-element='input'
          disabled={hideSearch}
          onSubmit={navigateToSearchPage}
        />

        <span
          className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5'
          data-kt-search-element='spinner'
        >
          <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
        </span>

        <span
          className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4'
          data-kt-search-element='clear'
        >
          <KTIcon iconName='cross' className='fs-2 text-lg-1 me-0' />
        </span>
      </form>

      <div
        data-kt-search-element='content'
        data-kt-menu='true'
        className='menu menu-sub menu-sub-dropdown w-300px w-md-350px py-7 px-7 overflow-hidden'
      >
        <div
          className={`${menuState === 'main' ? '' : 'd-none'}`}
          ref={wrapperElement}
          data-kt-search-element='wrapper'
        >
          <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
            <div className='scroll-y mh-200px mh-lg-350px'>
              <h3 className='fs-5 text-muted m-0 pt-5 pb-5' data-kt-search-element='category-title'>
                Pastes
              </h3>

              {loadingPastes ? (
                <div className='text-center'>
                  Loading...
                  <span className='spinner-border spinner-border-sm text-center ms-2'>
                  </span>
                </div>
              ) : pastes.length > 0 ? (
                pastes.map((paste, index) =>
                  <Link key={index} to={"/p/" + paste.pasteKey} className='d-flex text-dark text-hover-primary align-items-center mb-5'>
                    <div className='symbol symbol-40px me-4'>
                      <span className='symbol-label bg-light'>
                        <KTIcon iconName='document' className='fs-2qx' />
                      </span>
                    </div>

                    <div className='d-flex flex-column justify-content-start fw-bold'>
                      <span className='fs-6 fw-bold'>{paste.title}</span>
                      <span className='fs-7 fw-bold text-muted'>{paste.content.substring(0, 36)}...</span>
                    </div>
                  </Link>
                )) : (
                <div className='text-center'>
                  <div className='pt-10 pb-10'>
                    <KTIcon iconName='search-list' className='fs-4x opacity-50' />
                  </div>

                  <div className='pb-15 fw-bold'>
                    <h3 className='text-gray-600 fs-5 mb-2'>No paste found</h3>
                    <div className='text-muted fs-7'>Please try again with a different query</div>
                  </div>
                </div>
              )}

              < h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                Users
              </h3>

              {
                loadingUsers ? (
                  <div className='text-center'>
                    Loading...
                    <span className='spinner-border spinner-border-sm text-center ms-2'>
                    </span>
                  </div>
                ) : users.length > 0 ? (
                  users.map((user, index) =>
                    <Link key={index} to={"/user/" + user.username + "/overview"} className='d-flex text-dark text-hover-primary align-items-center mb-5'>
                      <div className='symbol symbol-40px me-4'>
                        {/* user profile pic */}
                        <UserProfilePicture imageUrl={user?.userDisplayPictureUrl} />
                      </div>

                      <div className='d-flex flex-column justify-content-start fw-bold'>
                        <span className='fs-6 fw-bold'>{user.username}</span>
                        <span className='fs-7 fw-bold text-muted'>{user.firstName}</span>
                      </div>
                    </Link>
                  )) : (
                  <div className='text-center'>
                    <div className='pt-10 pb-10'>
                      <KTIcon iconName='search-list' className='fs-4x opacity-50' />
                    </div>

                    <div className='pb-15 fw-bold'>
                      <h3 className='text-gray-600 fs-5 mb-2'>No user found</h3>
                      <div className='text-muted fs-7'>Please try again with a different query</div>
                    </div>
                  </div>
                )}
            </div>
          </div>

          <div ref={suggestionsElement} data-kt-search-element='main'>
            <div className='d-flex flex-stack fw-bold mb-4'>
              <span className='text-muted fs-6 me-2'>Recently Created:</span>
            </div>

            <div className='scroll-y mh-200px mh-lg-325px'>
              {suggestionPastes.map((paste, index) => {
                return (
                  <Link key={index} to={"/p/" + paste.pasteKey} className='d-flex align-items-center mb-5'>
                    <div className='symbol symbol-40px me-4'>
                      <span className='symbol-label bg-light'>
                        <KTIcon iconName='document' className='fs-2 text-primary' />
                      </span>
                    </div>

                    <div className='d-flex flex-column'>
                      <div className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                        {paste.title}
                      </div>
                      <span className='fs-7 text-muted fw-bold'>{paste.content.substring(0, 36)}...</span>
                    </div>
                  </Link>
                )
              })}
            </div>

          </div>
        </div>

        <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
          <div className='pt-10 pb-10'>
            <KTIcon iconName='search-list' className='fs-4x opacity-50' />
          </div>

          <div className='pb-15 fw-bold'>
            <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
            <div className='text-muted fs-7'>Please try again with a different query</div>
          </div>
        </div>
      </div>

      {/* for advanced search menu */}

    </div >
  )
}

export { Search }
