import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { User, UsersQueryResponse } from './_models'

const API_URL = "deprecated"
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`

const PASTEHUB_API_URL = process.env.REACT_APP_PASTEHUB_API_URL
const PASTE_API_URL = `${PASTEHUB_API_URL}/internal/paste`

export const getPastesByCreatedBy = (createdBy: string, showPrivate: boolean, query: string): Promise<AxiosResponse> => {
  return axios.get(`${PASTE_API_URL}?${query}&createdBy=${createdBy}&showPrivate=${showPrivate}`)
    .then((response: AxiosResponse) => {
      return response.data
    })
}

export const deletePasteByPasteKey = (pasteKey: string): Promise<void> => {
  return axios.delete(`${PASTE_API_URL}/${pasteKey}`)
    .then(() => { })
}

export const deleteSelectedPastesByPasteKey = (pasteKeys: Array<ID>): Promise<void> => {
  const requests = pasteKeys.map((pasteKey) => axios.delete(`${PASTE_API_URL}/${pasteKey}`))
  return axios.all(requests).then(() => { })
}

// template functions for reference :)
const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser }
