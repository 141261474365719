import { configureStore } from '@reduxjs/toolkit'
import pastesSlice from './redux/pastesSlice'
import usersSlice from './redux/usersSlice'
import commentsSlice from './redux/commentsSlice'

export const store = configureStore({
  reducer: {
    users: usersSlice,
    pastes: pastesSlice,
    comments: commentsSlice
  }
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch