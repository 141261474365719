/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'
import { Languages } from '../header-menus/Languages'
import { roleMappings } from '../../../../app/modules/users/_roleMappings'
import { UserProfilePicture } from '../../../../app/modules/profile/UserProfilePicture'
import { toast } from 'react-toastify'

const UserMenu = () => {
  const { currentUser, logout } = useAuth()

  function renderUserBadge() {
    const userRole = currentUser?.userRole ? roleMappings.get(currentUser?.userRole) : 'Guest'

    switch (userRole) {
      case 'Guest':
      case 'Basic':
        return (
          <>
            <span className='badge badge-light-success badge-circle fw-bolder fs-8'>
              {userRole}
            </span>
          </>
        )
      case 'Premium':
        return (
          <>
            <span className='badge badge-light-primary badge-circle fw-bolder fs-8'>
              {userRole}
            </span>
          </>
        )
      case 'Power':
      case 'Administrator':
        return (
          <>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-8'>
              {userRole}
            </span>
          </>
        )
      default:
        return null
    }
  }

  function handleLogout() {
    logout()
    toast.success('You have been logged out!')
  }

  function renderUserName() {
    if (!currentUser) {
      return 'Guest'
    }
    if (currentUser?.firstName || currentUser?.lastName) {
      return `${currentUser?.firstName} ${currentUser?.lastName}`
    }
    return currentUser?.username
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {/* begin::Menu item */}
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            <UserProfilePicture imageUrl={currentUser?.userDisplayPictureUrl} />
          </div>
          {/* end::Avatar */}

          {/* begin::Username */}
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              <div className='d-flex flex-column flex-md-row align-items-start text-break'>
                {renderUserName()}
                <span className='badge badge-light-success fw-bolder fs-8 px-3 py-0 ms-1'>
                  {renderUserBadge()}
                </span>
              </div>
            </div>
            <div className='fw-bold text-muted text-hover-primary fs-7 text-break'>
              {currentUser?.emailAddress}
            </div>
          </div>
          {/* end::Username */}
        </div>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className='separator my-2'></div>
      {/* end::Menu separator */}

      {/* TODO: add multiple languages support */}
      {/* <Languages languageMenuPlacement='right-end' /> */}

      {/* begin::Menu item */}
      <div className='menu-item px-5 my-1'>
        <Link to="/account/overview" className='menu-link px-5'>
          Account Settings
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        {currentUser ?
          <a onClick={handleLogout} className='menu-link px-5'>
            Sign Out
          </a> :
          <Link to={'/auth/login'} className='menu-link px-5'>Sign In/Register</Link>
        }
      </div>
    </div>
  )
}

export { UserMenu }
