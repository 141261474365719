import axios from 'axios'
import { UserRedactedModel } from '../../auth'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IGetUser } from '../UserModel'

const API_URL = process.env.REACT_APP_PASTEHUB_API_URL

export const USERS_URL = `${API_URL}/internal/users`
export const DOWNLOAD_URL = `${API_URL}/internal/file`

// https://stackoverflow.com/questions/63439021/handling-errors-with-redux-toolkit
export const getUser = createAsyncThunk(
    'users/getUser', async (userReq: IGetUser, { rejectWithValue }) => {
        try {
            const response = await axios.get<UserRedactedModel>(USERS_URL + '?id=' + userReq.userId + '&username=' + userReq.username)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })

export const searchUsersRedactedByQuery = createAsyncThunk(
    'users/searchUsersByQuery', async (query: string, { rejectWithValue }) => {
        try {
            const page = 1
            const size = 10
            const response = await axios.get<UserRedactedModel>(USERS_URL + '/search?q=' + query + '&page=' + page + '&size=' + size)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })