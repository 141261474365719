
import { KTIcon } from '../../../../_metronic/helpers/components/KTIcon';
import { CodeSyntaxDropdown } from '../components/CodeSyntaxDropdown';

import { CodeBlock } from '../components/CodeBlock';

const CodeViewer = (props) => {

    function handleCodeSyntaxChange(event: React.ChangeEvent<HTMLSelectElement>) {
        props.setCodeSyntax(event.target.value);
        props.formik?.handleChange(event);
    }

    return (
        <>
            <div
            >
                <div className="card-header d-flex flex-row" >
                    <h3 className="card-title">Syntax Highlighted Text
                        <div className='card-toolbar m-0'>
                            <div className="d-flex flex-column flex-start w-35px">
                                <button
                                    type='button'
                                    className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                >
                                    <KTIcon iconName='setting-3' className='fs-3 text-primary pulse' />
                                    <span className="pulse-ring"></span>
                                </button>
                                <CodeSyntaxDropdown codeSyntax={props.codeSyntax} onCodeSyntaxChange={handleCodeSyntaxChange} />
                            </div>
                            {props.codeSyntax ? <div className="d-flex flex-column flex-start w-35px">

                                <span className="badge badge-light-primary">
                                    <i className="ki-duotone ki-code w-20px text-primary">
                                        <i className="path1"></i>
                                        <i className="path2"></i>
                                        <i className="path3"></i>
                                        <i className="path4"></i>
                                    </i>{props.codeSyntax}</span>
                            </div> : ''}

                        </div>
                    </h3>
                    <div className="cursor-pointer collapsible rotate" data-bs-toggle="collapse" data-bs-target="#kt_docs_card_collapsible">
                        <div className="card-toolbar rotate-180">
                            <i className="ki-duotone ki-down fs-1"></i>
                        </div>
                    </div>
                </div>
                <div id="kt_docs_card_collapsible" className="collapse show">
                    <div className="card-body">
                        <CodeBlock code={props.content} language={props.codeSyntax} />
                    </div>
                </div>
            </div>
        </>
    )
}

export { CodeViewer }