import { Dispatch, FC, useEffect, useState, SetStateAction, ReactElement } from 'react';
import { useAppDispatch } from '../../hooks';
import clsx from 'clsx';

type Props = {
    totalResultCount: number
    page: number
    size: number
    setPage: Dispatch<SetStateAction<number>>
}

const SearchPagination: FC<Props> = ({ totalResultCount, page, size, setPage }) => {
    const totalPages = Math.ceil(totalResultCount / size)
    useEffect(() => {

    }, [page])

    const renderPreviousButton = () => {
        if (page <= 1) {
            return null
        }

        return (
            <button className='btn btn-light bg-transparent mx-3' onClick={() => setPage(page - 1)}> Previous</button >
        )
    }

    const renderNextButton = () => {
        if (page >= totalPages) {
            return null
        }
        return (
            <button className='btn btn-light bg-transparent mx-3' onClick={() => setPage(page + 1)}>Next</button>
        )
    }

    const renderLastPageButton = () => {
        if (totalPages <= 1) return null
        return (
            <button
                key={totalPages}
                className={clsx('btn mx-1 px-5 py-3', {
                    'btn-primary': page === totalPages,
                    'btn-light-primary': page !== totalPages,
                })}
                onClick={() => setPage(totalPages)}
            >
                {totalPages}
            </button>
        )

    }

    const renderPageButtons = () => {
        const display: ReactElement[] = []
        const maxButtons = 5

        const firstButton = (
            <button
                key={1}
                className={clsx('btn mx-1 px-5 py-3', {
                    'btn-primary': page === 1,
                    'btn-light-primary': page !== 1,
                })}
                onClick={() => setPage(1)}
            >
                1
            </button>
        )
        display.push(firstButton)
        // Calculate the range of page numbers to render
        const startPage = Math.max(2, page - Math.floor(maxButtons / 2));
        const endPage = Math.min(totalPages - 1, startPage + maxButtons - 1);

        if (totalPages > 1) {
            // Loop through the range of page numbers and render each button
            for (let i = startPage; i <= endPage; i++) {
                display.push(
                    <button
                        key={i}
                        className={clsx('btn mx-1 px-5 py-3', {
                            'btn-primary': page === i,
                            'btn-light-primary': page !== i,
                        })}
                        onClick={() => setPage(i)}
                    >
                        {i}
                    </button>
                )
            }
        }

        return display
    }
    return (
        <div>
            {renderPreviousButton()}
            {renderPageButtons()}
            {renderLastPageButton()}
            {renderNextButton()}
        </div>
    );
};

export { SearchPagination };