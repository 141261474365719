import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { downloadFile, getMeUser, refreshToken } from './_authRequests'
import { WithChildren } from '../../../../_metronic/helpers'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, saveAuth } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken before rendering the application

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptcha = async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha('refreshToken')
      return token
    }
  };

  useEffect(() => {
    const requestUser = async (username: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getMeUser(username)
          setCurrentUser(data)
          if (data && data.hasDisplayPicture) {
            const fileKey = 'user-dp/' + data?.id + '-dp';
            await downloadFile(fileKey)
              .then((arr) => {
                const url = URL.createObjectURL(arr.data);
                data.userDisplayPictureUrl = url;
              }).catch((err) => {
                // fail silently
              }).finally(() => {
                setCurrentUser(data)
              });
          }
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          // If the access token is invalid, try refreshing it
          try {
            const token = await handleReCaptcha();
            const { data } = await refreshToken(auth?.refreshToken || '', token ? token : '')
            if (data) {
              // Set the new access token in the auth object
              saveAuth(data)
              requestUser(username)
              return
            }
          } catch (error) {
            // console.error(error)
          }
        }

        // If refreshing the access token fails or if the error is not related to an invalid token, log the user out
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser(auth.username)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
