import { createSelector, createSlice } from '@reduxjs/toolkit'
import { PasteComment } from '../modules/pastes/PasteModels'
import { createPasteComment, deletePasteComment, getPasteCommentsByPasteKey, updatePasteCommentLikesDislikes } from '../modules/pastes/api/_pasteRequests'

interface PasteCommentState {
    comments: PasteComment[]
    status: 'idle' | 'loading' | 'failed' | 'succeeded' | 'fulfilled'
    error?: any
}

const initialState: PasteCommentState = {
    comments: [],
    status: 'idle',
}

const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(createPasteComment.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(createPasteComment.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.comments = state.comments.concat(action.payload)
            })
            .addCase(createPasteComment.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getPasteCommentsByPasteKey.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getPasteCommentsByPasteKey.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.comments = state.comments.concat(action.payload)
            })
            .addCase(getPasteCommentsByPasteKey.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updatePasteCommentLikesDislikes.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updatePasteCommentLikesDislikes.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // update the paste to the array
                const existingPasteComment = state.comments.find(pasteComment => pasteComment.id === action.payload.id)
                if (existingPasteComment) {
                    existingPasteComment.likeCount = action.payload.likeCount
                    existingPasteComment.dislikeCount = action.payload.dislikeCount
                    existingPasteComment.likedUsers = action.payload.likedUsers
                    existingPasteComment.dislikedUsers = action.payload.dislikedUsers
                }
            })
            .addCase(updatePasteCommentLikesDislikes.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deletePasteComment.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(deletePasteComment.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // remove paste comment from the array
                state.comments = state.comments.filter(pasteComment => pasteComment.id !== action.payload.id)
            })
            .addCase(deletePasteComment.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }

})

export const selectAllPasteComments = (state: { comments: PasteCommentState }) => state.comments.comments

export const selectPasteCommentsByPasteKey = (state: { comments: PasteCommentState }, pasteKey: string | undefined) => state.comments.comments.filter(comment => comment.pasteKey === pasteKey)

export const selectPasteCommentsByCommentId = (state: { comments: PasteCommentState }, commentId: string | undefined) => state.comments.comments.filter(comment => comment.parentComment === commentId)

export default commentsSlice.reducer