/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

type Props = {
    element: string
    syntax: string
}

const SubMarkdownFormattingGuideCard: FC<Props> = ({
    element,
    syntax
}) => {
    return (
        <div className='card rounded border border-gray-300 mb-10 h-50'>
            <div className='card-header flex-nowrap border-0 pt-9'>
                <div className='card-title m-0'>
                    <div className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
                        {element}
                    </div>
                </div>
            </div>

            <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
                <div className='fs-2x fw-bolder mb-3'>{syntax}</div>
            </div>
        </div>
    )
}

export { SubMarkdownFormattingGuideCard }
