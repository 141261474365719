/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, Suspense } from 'react'
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { CreatePasteWrapper } from '../pages/paste/CreatePasteWrapper'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { DisableSidebar } from '../../_metronic/layout/core'
import { WithChildren } from '../../_metronic/helpers'
import ViewPasteWrapper from '../pages/paste/ViewPasteWrapper'
import { EditPasteWrapper } from '../pages/paste/EditPasteWrapper'
import { AuthenticationRequredCard } from '../modules/auth/components/AuthenticationRequiredCard'
import { SearchViewWrapper } from '../pages/search/SearchViewWrapper'
import { LandingPage } from '../pages/landing/LandingPage'
import { AccountPage } from '../modules/accounts/AccountPage'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

export const AuthenticatedUserProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  // const navigate = useNavigate();
  if (!currentUser) {
    // user is not authenticated
    return <AuthenticationRequredCard />
  }
  return children;
};

const router = createBrowserRouter(
  [
    {
      path: "/", element: <App />, errorElement: <ErrorsPage />,
      children: [
        { path: "", element: <LandingPage /> },
        { path: "auth/*", element: <AuthPage /> },
        { path: "logout", element: <Logout /> },
        { path: "error/*", element: <ErrorsPage /> },
        {
          path: "create", element: <MasterLayout />,
          children: [
            { path: "", element: <CreatePasteWrapper /> },
          ]
        },
        {
          path: "p/", element: <MasterLayout />,
          children: [
            {
              path: ":pasteKey", element: <ViewPasteWrapper />,
            },
            {
              path: ":pasteKey/edit", element: <EditPasteWrapper />
            }
          ]
        },
        {
          path: 'account/', element: <MasterLayout />,
          children: [
            {
              path: "*", element:
                <AuthenticatedUserProtectedRoute >
                  <AccountPage />
                </AuthenticatedUserProtectedRoute >
            }]
        },
        {
          path: 'search', element: <MasterLayout />,
          children: [
            {
              path: "*", element: <SearchViewWrapper />, index: true
            }
          ]
        },
        {
          path: 'user/', element: <MasterLayout />,
          children: [
            {
              path: "*", lazy: async () => {
                let { ProfilePage } = await import('../pages/profile/ProfilePage')
                return {
                  element:
                    <SuspensedView>
                      <ProfilePage />
                    </SuspensedView>
                }
              }
            }]
        },
        {
          path: 'faq', element: <MasterLayout />,
          children: [
            {
              path: "", lazy: async () => {
                let { FaqPage } = await import('../pages/static/FaqPage')
                return {
                  element:
                    <SuspensedView>
                      <FaqPage />
                    </SuspensedView>
                }
              }
            }]
        },
        {
          path: 'about', element: <MasterLayout />,
          children: [
            {
              path: "", lazy: async () => {
                let { AboutPage } = await import('../pages/static/AboutPage')
                return {
                  element:
                    <SuspensedView>
                      <AboutPage />
                    </SuspensedView>
                }
              }
            }]
        },
        {
          path: 'terms', element: <MasterLayout />,
          children: [
            {
              path: "", lazy: async () => {
                let { TermsOfUsePage } = await import('../pages/static/TermsOfUsePage')
                return {
                  element:
                    <SuspensedView>
                      <TermsOfUsePage />
                    </SuspensedView>
                }
              }
            }]
        },
        {
          path: 'privacy', element: <MasterLayout />,
          children: [
            {
              path: "", lazy: async () => {
                let { PrivacyPolicyPage } = await import('../pages/static/PrivacyPolicyPage')
                return {
                  element:
                    <SuspensedView>
                      <PrivacyPolicyPage />
                    </SuspensedView>
                }
              }
            }]
        },
        {
          path: 'dmca', element: <MasterLayout />,
          children: [
            {
              path: "", lazy: async () => {
                let { DmcaReportPage } = await import('../pages/static/DmcaReportPage')
                return {
                  element:
                    <SuspensedView>
                      <DmcaReportPage />
                    </SuspensedView>
                }
              }
            }]
        },
        {
          path: 'abuse', element: <MasterLayout />,
          children: [
            {
              path: "", lazy: async () => {
                let { AbuseReportPage } = await import('../pages/static/AbuseReportPage')
                return {
                  element:
                    <SuspensedView>
                      <AbuseReportPage />
                    </SuspensedView>
                }
              }
            }]
        }
      ]
    },

  ], { basename: PUBLIC_URL });

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

const AppRoutes: FC = () => (
  <RouterProvider router={router} />
)

export { AppRoutes }
