/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'

const prismComponents = require("prismjs/components");

interface CodeSyntaxDropdownProps {
    codeSyntax: string;
    onCodeSyntaxChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function CodeSyntaxDropdown(props: CodeSyntaxDropdownProps) {

    const [codeSyntax, setCodeSyntax] = React.useState<string[]>([]);
    const [searchString, setSearchString] = React.useState<string>('');

    useEffect(() => {
        setCodeSyntax(Object.keys(prismComponents.languages).filter(v => v !== "meta" && v !== "crystal" && v !== "javadoc" && v !== "racket" && v !== "sparql").sort())
    }, [props])

    const filteredLanguages = codeSyntax.filter(lang =>
        lang.toLowerCase().includes(searchString.toLowerCase())
    );

    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Syntax Highlight</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
                <div className='mb-10'>
                    <label className='form-label fw-bold'>Language:</label>

                    <div>
                        <input type='text' className='form-control form-control-solid my-3' placeholder='Search languages...' value={searchString} onChange={(e) => setSearchString(e.target.value)} />
                        <select
                            name='syntax'
                            className='form-select form-select-solid'
                            data-placeholder='Select language'
                            value={props.codeSyntax}
                            onChange={props.onCodeSyntaxChange}
                        >
                            <option value='' />
                            {filteredLanguages
                                .sort()
                                .map(lang => (
                                    <option key={lang} value={lang}>{lang.charAt(0).toUpperCase() + lang.slice(1)}</option>
                                ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}