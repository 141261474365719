import TextareaAutosize from "react-textarea-autosize";

const StandardEditor = (props) => {

    const handleContentChange = event => {
        // 👇️ access textarea value
        props.setContent(event.target.value);
        props.formik.handleChange(event);
    };

    return (
        <>
            {/* <!--begin::input textarea--> */}
            <div className="card-body rounded">
                <TextareaAutosize
                    name="content"
                    className="form-control form-control-solid"
                    value={props.content}
                    minRows={12}
                    placeholder="Paste your text here..."
                    onChange={handleContentChange} />
                {props.formik.touched.content && props.formik.errors.content && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{props.formik.errors.content}</div>
                    </div>
                )}
                {/* <!--end::input textarea--> */}
            </div>
        </>
    )
}

export { StandardEditor }