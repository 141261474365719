/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

const AuthenticationRequredCard: React.FC = () => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="col-6">
                <div className="card">
                    {/* begin::Header */}
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">Authentication Required</span>
                            <span className="text-muted fw-semibold fs-7">Members-only area</span>
                        </h3>
                    </div>
                    {/* end::Header */}

                    {/* begin::Body */}
                    <div className="card-body d-flex flex-column text-center">
                        <div className="">
                            <p className="text-center fs-6 pb-5">
                                <span className="badge badge-light-warning fs-8">Pssh:</span>&nbsp; Hi there! Access members-only features by signing in or registering a new account.
                            </p>
                            <Link to="/auth/login" className="btn btn-danger w-100 py-3">
                                Sign In/Register
                            </Link>
                        </div>
                    </div>
                    {/* end::Body */}
                </div>
            </div>
        </div>
    );
};

export { AuthenticationRequredCard };
