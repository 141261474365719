import { FC } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'

type Props = {
  date?: string
}

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const PasteDateCell: FC<Props> = ({ date }) => (
  <div className='badge badge-light fw-bolder'>{dayjs(date).local().format('lll')}</div>
)

export { PasteDateCell }
