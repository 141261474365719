import axios from 'axios'
import { AuthModel, IResetPassword, IUpdateMeEmailAddress, IUpdateMePassword, IUpdateMeUser, IUploadFile, IVerifyEmail, UserModel } from './_models'

export const API_URL = process.env.REACT_APP_PASTEHUB_API_URL

export const ME_USER_URL = `${API_URL}/internal/me`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REFRESH_TOKEN_URL = `${API_URL}/auth/refreshtoken`
export const FORGOT_PASSWORD_URL = `${API_URL}/auth/forgotPassword`
export const RESET_PASSWORD_URL = `${API_URL}/auth/resetPassword`
export const VERIFY_EMAIL_URL = `${API_URL}/auth/verifyEmail`
export const UPLOAD_URL = `${API_URL}/internal/file`

// Server should return AuthModel
export function login(username: string, password: string, reCaptchaToken: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
    reCaptchaToken,
  })
}

// Server should return AuthModel
export function register(
  username: string,
  email: string,
  firstname: string,
  lastname: string,
  country: string,
  password: string,
  reCaptchaToken: string
) {
  return axios.post<AuthModel>(REGISTER_URL, {
    username,
    emailAddress: email,
    firstName: firstname,
    lastName: lastname,
    countryCode: country,
    password,
    reCaptchaToken,
  })
}

export function refreshToken(refreshToken: string, reCaptchaToken: string) {
  return axios.post<AuthModel>(REFRESH_TOKEN_URL, {
    refreshToken,
    reCaptchaToken
  })
}

export function forgotPassword(email: string, reCaptchaToken: string) {
  return axios.post(FORGOT_PASSWORD_URL, {
    emailAddress: email,
    reCaptchaToken
  })
}

export function resetPassword(params: IResetPassword) {
  return axios.post(RESET_PASSWORD_URL, {
    newPassword: params.newPassword,
    resetPasswordCode: params.resetPasswordCode,
    reCaptchaToken: params.reCaptchaToken
  })
}

export function verifyEmail(params: IVerifyEmail) {
  return axios.post(VERIFY_EMAIL_URL, {
    verificationCode: params.verificationCode,
    reCaptchaToken: params.reCaptchaToken
  })
}

export function updateAccessTokenWithRefreshToken(refreshToken: string) {
  return axios.post<AuthModel>(REFRESH_TOKEN_URL, {
    refreshToken,
  })
}

export function getMeUser(username: string) {
  return axios.get<UserModel>(ME_USER_URL + '/' + username)
}

export function updateMePassword(values: IUpdateMePassword) {
  return axios.put<UserModel>(ME_USER_URL + "/" + values.currentUsername + '/details', values)
}

export function updateMeUser(values: IUpdateMeUser) {
  return axios.put<UserModel>(ME_USER_URL + "/" + values.currentUsername + '/details', values)
}

export function updateMeEmailAddress(values: IUpdateMeEmailAddress) {
  return axios.put<UserModel>(ME_USER_URL + "/" + values.currentUsername + '/email', values)
}

export function deleteMeUser(username: string) {
  return axios.delete(ME_USER_URL + '/' + username)
}

// 'type' refers to whether it is a paste attachment or a user avatar either,
// 1. 'dp'
// 2. 'paste'
export function uploadFile(upload: IUploadFile) {
  const formData = new FormData()

  // rename to upload file to identify it on the server
  if (upload.fileName && upload.file) {
    formData.append('file-data', upload.file, upload.fileName)
  }
  if (upload.fileType) {
    formData.append('fileType', upload.fileType)
  }
  if (upload.type) {
    formData.append('type', upload.type)
  }
  if (upload.id) {
    formData.append('id', upload.id)
  }
  if (upload.originalFileName) {
    formData.append('originalFileName', upload.originalFileName)
  }

  return axios.post(UPLOAD_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function downloadFile(fileKey: string) {
  return axios.get(`${API_URL}/internal/file?key=${fileKey}`, {
    responseType: 'blob'
  })
}
