/* eslint-disable jsx-a11y/anchor-is-valid */
import { QRCodeCanvas } from 'qrcode.react'
import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import {
    FacebookShareButton,
    FacebookIcon,
    // FacebookMessengerShareButton,
    // FacebookMessengerIcon,
    LineShareButton,
    LineIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PocketShareButton,
    PocketIcon,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";

type Props = {
    qrCodeUrl: string
    bgColor: string
}

const SubSuccessPasteCreationCard: React.FC<Props> = ({
    qrCodeUrl,
    bgColor
}) => {
    return (
        <div className={`card bg-${bgColor} hoverable`}>
            <div className='card-body'>
                <div className='row'>
                    <div className="col-xl-3">
                        <QRCodeCanvas
                            level='Q'
                            value={qrCodeUrl}
                            includeMargin={true}
                            imageSettings={{
                                src: toAbsoluteUrl('/media/logos/pasteHub.svg'),
                                x: undefined,
                                y: undefined,
                                height: 24,
                                width: 24,
                                excavate: true,
                            }}
                        />
                    </div>
                    <div className='col-xl-9'>
                        <div className='row'>
                            <div className={`fw-bold fs-2`}>Well Done!</div>
                            <div className={`fw-semibold `}>
                                You can view your paste at <Link to={qrCodeUrl}>{qrCodeUrl}</Link>.
                                Or share the QR Code with your friends or colleagues!
                            </div>
                        </div>
                        <br />
                        <div className='row min-h-30px'>
                            {/* TODO: possibly add more social sharing options? */}
                            <div className='col'>
                                <FacebookShareButton url={qrCodeUrl} className='min-h-30px' >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                            </div>
                            <div className='col'>
                                <LinkedinShareButton url={qrCodeUrl} className='min-h-30px'  >
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                            </div>
                            <div className='col'>
                                <RedditShareButton url={qrCodeUrl} className='min-h-30px'  >
                                    <RedditIcon size={32} round />
                                </RedditShareButton>
                            </div>
                            <div className='col'>
                                <TwitterShareButton url={qrCodeUrl} className='min-h-30px'  >
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                            </div>
                            <div className='col'>
                                <TelegramShareButton url={qrCodeUrl} className='min-h-30px'  >
                                    <TelegramIcon size={32} round />
                                </TelegramShareButton>
                            </div>
                            <div className='col'>
                                <WhatsappShareButton url={qrCodeUrl} className='min-h-30px'>
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>
                            <div className='col'>
                                <PocketShareButton url={qrCodeUrl} className='min-h-30px'>
                                    <PocketIcon size={32} round />
                                </PocketShareButton>
                            </div>
                            <div className='col'>
                                <LineShareButton url={qrCodeUrl} className='min-h-30px'>
                                    <LineIcon size={32} round />
                                </LineShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { SubSuccessPasteCreationCard }
