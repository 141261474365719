/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Paste } from '../../../PasteModels'

type Props = {
  paste: Paste
}

const PasteInfoCell: FC<Props> = ({ paste }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column '>
      <Link to={"/p/" + paste.pasteKey} className='text-gray-800 text-hover-primary mb-1'>
        {paste.title}
      </Link>
      {/* preview of the content? */}
      <span>{paste.content.substring(0,21)}...</span>
    </div>
  </div>
)

export { PasteInfoCell }
