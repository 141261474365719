import React, { useEffect } from 'react'

type Props = {
    className: string
}

declare global {
    interface Window {
        adsbygoogle?: {
            [key: string]: unknown;
        }[];
    }
}

const AdsenseBlock: React.FC<Props> = ({ className }) => {

    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, [])

    return (
        <div>
            <ins className={`adsbygoogle ${className}`}
                style={{ display: 'block' }}
                data-ad-client="ca-pub-2011950874975325"
                data-ad-slot="5730433972"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
        </div>
    )
}

export { AdsenseBlock }