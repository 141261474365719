import { FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Paste } from "../PasteModels";
import { DynamicFileViewer } from "./DynamicFileViewer";
import { KTIcon } from "../../../../_metronic/helpers";
import { isRenderableFileType } from "./renderableFileTypes";

type Props = {
    paste: Paste | undefined;
};

const FileViewerWrapper: FC<Props> = ({ paste }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return <Skeleton count={15} />;
    }

    return (
        <>
            {/* we only render the Card component if the paste has an uploaded file and the file is renderable */}
            {paste?.uploadedFileSizeInBytes && paste?.uploadedFileSizeInBytes > 0 && paste?.uploadedFileType && isRenderableFileType(paste?.uploadedFileType) ? (
                <div className="card card-flush shadow-sm">
                    <div className="card-header collapsible cursor-pointer rotate" data-bs-toggle="collapse" data-bs-target="#paste_attachment_card_collapsible">
                        <div className="card-title"><KTIcon iconName="file" className="fs-2x" /></div>
                        <div className="card-toolbar rotate-180">
                            <i className="ki-duotone ki-down fs-1"></i>
                        </div>
                    </div>
                    <div id="paste_attachment_card_collapsible" className="collapse show">
                        <div className="card-body">
                            <DynamicFileViewer paste={paste} />
                        </div>
                    </div>
                </div>) : (<div className="my-3" />)}
        </>
    );
};

export { FileViewerWrapper };
