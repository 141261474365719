import { FC } from 'react'

type Props = {
  count?: string
  statusColor?: string | "success" | "primary" | "danger" | "warning" | "info" | "dark" | "secondary" | "light"
}

const PasteCountCell: FC<Props> = ({ count, statusColor }) => (
  // <div className={`badge badge-light-${statusColor} fw-bolder fs-6`}>{count}</div>
  <div>{count}</div>
)

export { PasteCountCell }
