/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getMeUser, register } from '../core/_authRequests'
import { Link, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { countries } from 'countries-list'
import { handleFormErrors } from '../../errors/errorHelpers'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const initialValues = {
  username: '',
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  country: '',
  changepassword: '',
  acceptTerms: false,
  reCaptchaToken: '',
}

const registrationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Minimum 3 character')
    .max(66, 'Maximum 66 characters')
    .matches(/^[a-zA-Z0-9._-]{3,}$/, 'Cannot contain special characters or spaces')
    .required('Username is required'),
  firstname: Yup.string()
    .min(1, 'Minimum 1 character')
    .max(66, 'Maximum 66 characters')
    .matches(/^$|[\p{L}\s]{1,}$/u, 'Cannot contain special characters or spaces')
    .notRequired(),
  email: Yup.string()
    .email('Wrong email address format')
    .min(3, 'Minimum 3 characters')
    .max(66, 'Maximum 66 characters')
    .required('Email address is required'),
  lastname: Yup.string()
    .min(1, 'Minimum 1 character')
    .max(66, 'Maximum 66 characters')
    .matches(/^$|[\p{L}\s]{1,}$/u, 'Cannot contain special characters or spaces')
    .notRequired(),
  country: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'Cannot contain special characters or spaces')
    .required('Country is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 character')
    .max(66, 'Maximum 66 characters')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 character')
    .max(66, 'Maximum 66 characters')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptcha = async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha('registration')
      return token
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      const token = await handleReCaptcha();
      values.reCaptchaToken = token ? token : '';

      try {
        const registerRes = await register(
          values.username,
          values.email,
          values.firstname,
          values.lastname,
          values.country,
          values.password,
          values.reCaptchaToken
        )
        saveAuth(registerRes.data)
        try {
          const meUser = await getMeUser(values.username)
          setCurrentUser(meUser.data)
          navigate('/create')
        } catch (error) {
          handleFormErrors(error, setStatus)
        }
      }
      catch (error) {
        // catch user registration errors
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
        handleFormErrors(error, setStatus)
      }
    }
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}

      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {/* begin::Form group username */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Username</label>
        <input
          placeholder='Username'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.username && formik.errors.username,
            },
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>First name</label>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      <div className='fv-row mb-8'>
        {/* begin::Form group Country */}
        <label className='form-label fw-bolder text-dark fs-6 required'>Country</label>
        <select
          className={clsx(
            'form-control bg-transparent form-select',
            {
              'is-invalid': formik.touched.country && formik.errors.country,
            },
            {
              'is-valid': formik.touched.country && !formik.errors.country,
            }
          )}
          {...formik.getFieldProps('country')}
        >
          <option></option>
          {Object.entries(countries)
            .sort()
            .map(([code, name]) => (
              <option key={code} value={code}>
                {name.name}
              </option>
            ))}
        </select>
        {formik.touched.country && formik.errors.country && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.country}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Email Address</label>
        <input
          placeholder='foodExplorer@example.com'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Confirm Password</label>
        <input
          type='password'
          placeholder='Confirm Password'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline required' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the
            <Link
              to='/terms'
              target='_blank'
              rel="noopener noreferrer"
              className='ms-1 link-primary'
            >
              Terms & Conditions
            </Link>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
