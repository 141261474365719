import { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { useAppDispatch } from '../../hooks';
import { KTIcon } from '../../../_metronic/helpers';
import { SearchResult } from '../../modules/search/SearchResult';
import { searchPastesByQuery } from '../../modules/pastes/api/_pasteRequests';
import { IQueryPaste, Paste } from '../../modules/pastes/PasteModels';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { PasteNotFoundCard } from '../../modules/pastes/components/PasteNotFoundCard';
import { SearchPagination } from '../../modules/search/SearchPagination';


const SearchViewPage: FC = () => {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const [size] = useState<number>(10)
    const [totalResultCount, setTotalResultCount] = useState<number>(0)
    const [pastes, setPastes] = useState<Paste[]>([])

    const dispatch = useAppDispatch()

    const query = {
        query: searchQuery,
        page: page,
        size: size,
        showPrivate: false
    } as IQueryPaste


    const [searchParams, setSearchParams] = useSearchParams();

    const fetchSearchResults = async (optionalSearchParam: string | null) => {
        setIsLoading(true)

        if (optionalSearchParam) {
            query.query = optionalSearchParam
            searchParams.delete("search")
            setSearchParams(searchParams)
        }

        await dispatch(searchPastesByQuery(query))
            .unwrap()
            .then((res) => {
                setPastes(res.pastes)
                setTotalResultCount(res.count)
            }).catch((err) => {
                setTotalResultCount(0)
            }).finally(() => {
                setIsLoading(false)
            })

    }

    useEffect(() => {
        fetchSearchResults(searchParams.get("search"))
    }, [page])

    const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setPage(1)
        await fetchSearchResults(null)
    }

    const displayLoading = () => (
        <span className='indicator-progress' style={{ display: 'block' }}>
            Please wait...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2x'></span>
        </span>
    )

    if (isLoading) return (displayLoading())

    return (
        <>
            <div className="d-flex flex-row flex-center">
                <div className='row col-md-6 col-sm-12'>
                    <form className='form control' onSubmit={handleSearch}>
                        <div className="input-group mb-5">
                            <span className="input-group-text" id="basic-addon1">
                                <KTIcon iconName='magnifier' />
                            </span>
                            <input
                                autoFocus
                                type="text"
                                className="form-control"
                                placeholder="Search pastes..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div className='divider my-3'></div>
            <div className='d-flex flex-column align-items-center'>
                {pastes.length > 0 ? pastes.map(paste => (
                    <div key={paste.id} className='row col-md-8 col-sm-12'>
                        <SearchResult key={paste.id} paste={paste} />
                    </div>
                )) : <PasteNotFoundCard className='row col-12' />}
            </div>

            <div className='divider my-3'></div>
            <div className='d-flex flex-row flex-end'>
                {/* pagination */}
                <SearchPagination size={size} page={page} setPage={setPage} totalResultCount={totalResultCount} />
            </div>
        </>
    );
};

const SearchViewWrapper: FC = () => {

    return (
        <>
            {/* page description display created by who */}
            <PageTitle description={`Discover useful or interesting pastes`} breadcrumbs={[]}>
                {/* display paste title */}
                Search
            </PageTitle>

            <SearchViewPage />
        </>
    );
};

export { SearchViewWrapper };
