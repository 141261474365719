import { useCallback, useEffect, useState } from 'react'
import { PageTitle, renderPageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth/core/Auth'

import { CreateEditPasteAndView } from '../../modules/pastes/components/CreateEditPasteAndView'
import { KTIcon } from '../../../_metronic/helpers/components/KTIcon'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ICreatePaste } from '../../modules/pastes/PasteModels'
import { RedirectToAuthModal } from '../../modules/auth/components/RedirectToAuthModal'
import { addNewPaste } from '../../modules/pastes/api/_pasteRequests'
import { RightToolbar } from '../../../_metronic/partials/layout/RightToolbar'
import { useAppDispatch } from '../../hooks'
import { SuccessPasteCreationModal } from '../../modules/pastes/components/SuccessPasteCreationModal'
import { handlePasteFormErrors } from '../../modules/errors/errorHelpers'
import { useDropzone } from 'react-dropzone'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import Jump from 'react-reveal/Jump';

import { Helmet } from "react-helmet";

import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { uploadFile } from '../../modules/auth/core/_authRequests'
import { IUploadFile } from '../../modules/auth'

const CreatePastePage = () => {

  const baseUrl = process.env.REACT_APP_BASE_URL
  const createdPasteUrlPrefix = baseUrl + '/p/'

  const [content, setContent] = useState<string>('');
  const [codeSyntax, setCodeSyntax] = useState<string>('')
  const [title] = useState<string>('')
  const [pasteKey] = useState<string>('')
  const [expirationDurationInMinutes] = useState<number>(43200)
  const [visibility] = useState<string>('public')
  const [syntax] = useState<string>('')
  const [reCaptchaToken] = useState<string>('createPaste')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false)
  const [createdPasteKey, setCreatedPasteKey] = useState<string>('')
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])

  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [uploadFileErrors, setUploadFileErrors] = useState<string[]>([])

  const expirationOptions = new Map<number, string>();
  expirationOptions.set(0, 'Never');
  expirationOptions.set(5, '5 minutes');
  expirationOptions.set(60, '1 hour');
  expirationOptions.set(1440, '1 day');
  expirationOptions.set(10080, '1 week');
  expirationOptions.set(20160, '2 weeks');
  expirationOptions.set(43200, '1 month');
  expirationOptions.set(525600, '1 year');

  const { mode } = useThemeMode()

  const createPasteFormValidationSchema = Yup.object().shape({
    title: Yup.string()
      .nonNullable()
      .min(1, 'Minimum 1 character')
      .max(66, 'Maximum 66 characters')
      .required('Title is required'),
    content: Yup.string()
      .nonNullable()
      .min(1, 'Minimum 1 character')
      .max(999999, 'Maximum 999,999 characters')
      .required('Paste content cannot be empty'),
    pasteKey: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(32, 'Maximum 32 characters')
      .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters allowed')
      .notRequired(),
    expirationDurationInMinutes: Yup.number()
      .min(0, 'Minimum value is 0')
      .max(525600, 'Maximum value is 525,600')
      .required('Expiration duration is required'),
    visibility: Yup.string()
      .required('Visibility is required'),
    syntax: Yup.string()
      .notRequired()
  })

  const { currentUser } = useAuth()

  const dispatch = useAppDispatch()

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptcha = async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha('createPaste')
      return token
    }
  };

  const handleAddNewPaste = async (values) => {

    values.reCaptchaToken = await handleReCaptcha();

    if (uploadedFile) {
      values.uploadedFileSizeInBytes = uploadedFile.size
      values.uploadedFileType = uploadedFile.type
    }

    try {
      const res = await dispatch(addNewPaste(values)).unwrap();
      const fileName = res.id + '-thing'

      const uploadForm = {
        file: uploadedFile,
        fileType: uploadedFile?.type === '' ? 'Unknown' : uploadedFile?.type,
        fileName: fileName,
        originalFileName: uploadedFile?.name,
        id: res.id,
        type: 'attachment'
      } as IUploadFile

      if (uploadedFile) {
        uploadFile(uploadForm)
          .finally(() => {
            setUploadedFile(null)
          })
      }

      // Successfully created paste!
      setErrors([]);

      const createdPasteUrl = createdPasteUrlPrefix + res.pasteKey;
      setCreatedPasteKey(createdPasteUrl);
      setShowSuccessModal(true);
    } catch (err) {
      handlePasteFormErrors(err, setErrors);
    } finally {
      setIsLoading(false);
    }
  };

  function handleRemoveFile() {
    setUploadedFile(null)
    setUploadFileErrors([])
  }

  const createPasteFormik = useFormik<ICreatePaste>({
    initialValues: { title, content, pasteKey, expirationDurationInMinutes, visibility, syntax, reCaptchaToken },
    validationSchema: createPasteFormValidationSchema,
    onSubmit: (values) => {
      setIsLoading(true)

      // check if user is authenticated before allowing creation of private paste
      if (!currentUser && values.visibility === 'private') {
        setIsLoading(false)
        setShowLoginModal(true)
        return
      }
      // make API request to backend to create paste
      handleAddNewPaste(values)
    }
  })

  const onDrop = useCallback((acceptedFile, fileRejections) => {
    // Do something with the files
    setUploadFileErrors([])
    setUploadedFile(acceptedFile[0])

    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {

        if (err.code === 'file-too-large') {
          err.message = 'File is too large. Maximum size is 10MB.'
        }

        setUploadFileErrors(prev => [...prev, err.message])
      })
    });
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, maxSize: 10000000 })

  useEffect(() => {

  }, [uploadedFile])

  return (
    <>
      <div className='card rounded'>
        <form
          onSubmit={createPasteFormik.handleSubmit}
          id='kt_signin_change_email'
          className='form'
          noValidate
        >
          <div className='card-body'>
            {/* begin::input and viewer */}
            <div className='mx-auto shadow-lg rounded'>
              <CreateEditPasteAndView
                mode='create'
                content={content}
                setContent={setContent}
                codeSyntax={codeSyntax}
                setCodeSyntax={setCodeSyntax}
                formik={createPasteFormik} />
              {/* end::input and viewer */}
            </div>
          </div>

          {/* begin::File Upload */}
          <div className="card-body row ">
            <div className='col-xl-4 col-md-5 mb-3 mb-md-0'>
              <div className="card card-bordered card-flush shadow-sm d-flex flex-column h-100" style={{ backgroundColor: mode === 'light' ? "#f3f6ff" : "#1e1e2d" }}>
                {/* <div className="card-header">
                  <h3 className="card-title fw-bolder">Paste Thing</h3>
                  <div className="card-toolbar"></div>
                </div> */}
                <div className="card-body mt-3 ">
                  {uploadedFile ? (
                    <div className='row '>
                      <div className='col-8'>
                        {/* file is uploaded metadata display */}
                        <div>
                          <h4 className='fw-bold'>File Size: </h4>
                          <p>{uploadedFile?.size ? `${(uploadedFile?.size / (1024 * 1024)).toFixed(2)}MB` : null}</p>
                        </div>
                        <div>
                          <h4 className='fw-bold'>File Type: </h4>
                          <p>{uploadedFile?.type ? uploadedFile?.type : null}</p>
                        </div>
                      </div>
                      <Jump>
                        <div className='col-4 d-flex align-items-center justify-content-center'>
                          <div className='text-center'>
                            <i className="far fa-solid fa-circle-check text-success fs-3x pulse" />
                          </div>
                        </div>
                      </Jump>
                      {/* file is uploaded metadata display */}
                    </div>) :
                    <div className='row'>
                      <div className='col-12'>
                        <div className='d-flex flex-column align-items-center justify-content-center h-100'>
                          <p className='text-muted'>Supplement your paste content with a file attachment!</p>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className='col-xl-8 col-md-7'>
              <div className="card shadow-sm h-100">
                <div className="d-flex flex-column h-100">
                  {uploadedFile ?
                    <div onClick={handleRemoveFile}>
                      <KTIcon iconName='delete-files' className='fs-3x position-absolute top-0 end-0 text-danger' />
                    </div> : null}
                  <div {...getRootProps()} className='form-control form-control-solid border flex-grow-1'>
                    <input {...getInputProps()} accept='*' />
                    <div className='d-flex flex-column align-items-center justify-content-center h-100 text-center'>
                      {uploadedFile ? (
                        <div className='row align-items-center'>
                          <div className='col-10 d-flex align-items-center'>
                            <div className='me-2'>File is ready for upload!</div>
                            <KTIcon iconName='file-added' className='fs-3tx text-success' />
                          </div>

                        </div>
                      ) : (
                        <div>
                          <p className='fw-bold'>Drag 'n' drop a file here, or click to select a file</p>
                          <KTIcon iconName='file-up' className='fs-3tx' />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {uploadFileErrors.length > 0 ?
              <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed mt-6 p-6'>
                <KTIcon iconName='information-5' className='fs-2tx text-danger me-4' />
                <div className='d-flex flex-stack flex-grow-1'>
                  <div className='fw-bold'>
                    <h4 className='text-gray-800 fw-bolder'>Error Uploading File</h4>
                    <div className='fs-6 text-gray-600'>
                      {uploadFileErrors.length > 0
                        ? uploadFileErrors.map((error, index) => (
                          <div key={index}>
                            {error}
                          </div>
                        ))
                        : null}
                    </div>
                  </div>
                </div>
              </div> : null}
          </div>
          {/* end::File Upload */}

          <div className="card-body" >
            <div className="card card-flush shadow-sm">
              <div className="card-header">
                <div className="card-title">
                  <span className='fw-bolder'>Paste Settings</span>
                </div>
              </div>
              <div className='card-body card-scroll'>
                {/* begin::Paste Name/Title */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Icon */}
                  <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-success'>
                    <KTIcon iconName='message-text' className='text-success fs-3x text-lg-3x' />
                  </div>
                  {/* end::Icon */}
                  {/* begin::Info */}
                  <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                    {/* begin::Wrapper */}
                    <div className='d-flex flex-column me-2 me-lg-5'>
                      {/* begin::Title */}
                      <span
                        className='text-dark required text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'>
                        Paste Name/Title
                      </span>
                      {/* end::Title */}
                      {/* begin::Description */}
                      <div className='text-muted fw-bold fs-7 fs-lg-6'>
                        Get creative with your paste title! It's the perfect opportunity to grab attention. Remember, this field must not be left empty.
                      </div>
                      {/* end::Description */}
                    </div>
                    {/* end::Wrapper */}

                  </div>
                  {/* end::Info */}

                </div>
                <div className='mx-auto'>
                  <input type="text"
                    className="form-control form-control-solid"
                    id="kt_docs_maxlength_basic"
                    placeholder="My Paste Title"
                    {...createPasteFormik.getFieldProps('title')}
                  />
                  {createPasteFormik.touched.title && createPasteFormik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{createPasteFormik.errors.title}</div>
                    </div>
                  )}
                </div>
                {/* end::Paste Name/Title */}

                <br />

                {/* begin::Paste Unique Identifier */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Icon */}
                  <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-danger'>
                    <KTIcon iconName='key' className='text-danger fs-3x text-lg-3x' />
                  </div>
                  {/* end::Icon */}
                  {/* begin::Info */}
                  <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                    {/* begin::Wrapper */}
                    <div className='d-flex flex-column me-2 me-lg-5'>
                      {/* begin::Title */}
                      <span
                        className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                      >
                        Paste Unique Identifier
                      </span>
                      {/* end::Title */}
                      {/* begin::Description */}
                      <div className='text-muted fw-bold fs-7 fs-lg-6'>
                        This will be the back half of the URL for your paste.
                        If you leave this empty, a random identifier will be generated for you.
                        Pick a memorable identifier!
                        Minimum 8 characters, maximum 64 characters.
                      </div>
                      {/* end::Description */}
                    </div>
                    {/* end::Wrapper */}

                  </div>
                  {/* end::Info */}

                </div>
                <div className='mx-auto'>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="iLoveYouMum"
                    {...createPasteFormik.getFieldProps('pasteKey')}
                  />
                  {createPasteFormik.touched.pasteKey && createPasteFormik.errors.pasteKey && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{createPasteFormik.errors.pasteKey}</div>
                    </div>
                  )}
                </div>
                {/* end::Paste Unique Identifier */}

                <br />

                {/* begin::Paste Expiration */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Icon */}
                  <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-warning'>
                    <KTIcon iconName='watch' className='text-warning fs-3x text-lg-3x' />
                  </div>
                  {/* end::Icon */}
                  {/* begin::Info */}
                  <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                    {/* begin::Wrapper */}
                    <div className='d-flex flex-column me-2 me-lg-5'>
                      {/* begin::Title */}
                      <span
                        className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                      >
                        Paste Expiration
                      </span>
                      {/* end::Title */}
                      {/* begin::Description */}
                      <div className='text-muted fw-bold fs-7 fs-lg-6'>
                        Determines how long before your paste expires. Default expiration duration is 1 month.
                      </div>
                      {/* end::Description */}
                    </div>
                    {/* end::Wrapper */}

                  </div>
                  {/* end::Info */}

                </div>
                {/* end::Link */}
                <div className="mx-auto">
                  <select
                    className="form-select form-select-solid"
                    data-placeholder="Select an expiration period"
                    {...createPasteFormik.getFieldProps('expirationDurationInMinutes')}
                    onChange={(event) => {
                      const selectedValue = parseInt(event.target.value, 10); // Convert the value to a number
                      createPasteFormik.setFieldValue('expirationDurationInMinutes', selectedValue);
                    }}
                  >
                    {Array.from(expirationOptions, ([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                    ))}
                  </select>
                  {createPasteFormik.touched.expirationDurationInMinutes && createPasteFormik.errors.expirationDurationInMinutes && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{createPasteFormik.errors.expirationDurationInMinutes}</div>
                    </div>
                  )}
                </div>
                {/* begin::Link */}
                {/* end::Paste Expiration */}
                <br />

                {/* begin::Paste Exposure */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Icon */}
                  <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-primary'>
                    <KTIcon iconName='eye' className='text-primary fs-3x text-lg-3x' />
                  </div>
                  {/* end::Icon */}
                  {/* begin::Info */}
                  <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                    {/* begin::Wrapper */}
                    <div className='d-flex flex-column me-2 me-lg-5'>
                      {/* begin::Title */}
                      <span
                        className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                      >
                        Paste Exposure
                      </span>
                      {/* end::Title */}
                      {/* begin::Description */}
                      <div className='text-muted fw-bold fs-7 fs-lg-6'>
                        Determines whether your paste can be viewed by the public or private only. Only members can create private pastes.
                      </div>
                      {/* end::Description */}
                    </div>
                    {/* end::Wrapper */}

                  </div>
                  {/* end::Info */}

                </div>
                <div className='mx-auto'>
                  <select
                    className="form-select form-select-solid"
                    id="visibility"
                    data-placeholder="Select an exposure type"
                    // onChange={(e) => { setVisibility(e.target.value) }}
                    {...createPasteFormik.getFieldProps('visibility')}
                  >
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                  </select>
                  {createPasteFormik.touched.visibility && createPasteFormik.errors.visibility && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{createPasteFormik.errors.visibility}</div>
                    </div>
                  )}
                </div>
                {/* end::Paste Exposure */}

                <br />
                <br />

                <div className='d-flex'>
                  <button
                    id='kt_signin_submit'
                    type='submit'
                    className='btn btn-lg explore-btn-primary w-100'
                    disabled={isLoading}
                  >
                    {!isLoading && 'Create Paste'}
                    {isLoading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>

                <br />

                {errors.length !== 0 && !isLoading ?
                  <div className='notice d-flex bg-light-danger rounded border-danger border p-6'>
                    <KTIcon iconName='information-5' className='fs-2tx text-danger me-4' />
                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-bold'>
                        <h4 className='text-gray-800 fw-bolder'>Error Creating Paste</h4>
                        {errors.map((error, index) => {
                          return <div className='fs-6 text-gray-600' key={index}>{error}</div>;
                        })}
                      </div>
                    </div>
                  </div> : ''}
              </div>
            </div>

          </div >
        </form >
      </div >
      <RedirectToAuthModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        title="Attention: Members-Exclusive Paste Zone"
        message="Unlock the feature of private pastes. Don't miss out! Create an account or log in to explore this exclusive feature."
      />
      <SuccessPasteCreationModal
        show={showSuccessModal}
        backdrop="static"
        keyboard={false}
        onHide={() => setShowSuccessModal(false)}
        url={createdPasteKey}
      />
    </>
  )
}

const CreatePasteWrapper = () => {
  const { currentUser } = useAuth()

  return (
    <>
      <PageTitle description='Start creating your paste' breadcrumbs={[]}>
        {renderPageTitle(currentUser)}
      </PageTitle>
      <Helmet defaultTitle="PasteThing - Share Your Knowledge with Others">
        <title>{`PasteThing - Create Your Paste`}</title>
        <meta name="description" content="PasteThing is a feature-rich SaaS platform for easy text and file sharing. Users can create Markdown-formatted or raw code pastes with syntax highlighting. It supports rendering and convenient viewing of PDFs, Word documents, and Excel files, allowing seamless downloading." />
        <meta name="robots" content="index, nofollow" />
      </Helmet>
      <CreatePastePage />
      <RightToolbar />
    </>
  )
}

export { CreatePasteWrapper }
