import axios from 'axios'
import { ICreatePaste, ICreatePasteComment, IUpdateLikeDislikePaste, IUpdateLikeDislikePasteComment, Paste, PasteComment, IDeletePasteComment, IUpdatePaste, IQueryPaste, IPasteSearchResponse } from '../PasteModels'
import { createAsyncThunk } from '@reduxjs/toolkit'

const API_URL = process.env.REACT_APP_PASTEHUB_API_URL

const PASTE_API_URL = `${API_URL}/internal/paste`

// https://stackoverflow.com/questions/63439021/handling-errors-with-redux-toolkit
export const addNewPaste = createAsyncThunk(
    'pastes/addNewPaste', async (paste: ICreatePaste, { rejectWithValue }) => {
        try {
            const response = await axios.post<Paste>(PASTE_API_URL, paste)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })

export const updatePaste = createAsyncThunk(
    'pastes/updatePaste', async (paste: IUpdatePaste, { rejectWithValue }) => {
        try {
            const response = await axios.put<Paste>(PASTE_API_URL + '/' + paste.pasteKey + '/details', paste)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })
export const getPasteByPasteKey = createAsyncThunk(
    'pastes/getPasteByPasteKey', async (pasteKey: string, { rejectWithValue }) => {
        try {
            const response = await axios.get<Paste>(PASTE_API_URL + '/' + pasteKey)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })

export const deletePasteByPasteKey = createAsyncThunk(
    'pastes/deletePasteByPasteKey', async (pasteKey: string, { rejectWithValue }) => {
        try {
            const response = await axios.delete<Paste>(PASTE_API_URL + '/' + pasteKey)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })

export const searchPastesByQuery = createAsyncThunk(
    'pastes/searchPastesByQuery', async (query: IQueryPaste, { rejectWithValue }) => {
        try {
            const response = await axios.get<IPasteSearchResponse>(PASTE_API_URL + '/search?q=' + query.query + '&page=' + query.page + '&size=' + query.size)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })

export const updatePasteLikesDislikes = createAsyncThunk(
    'pastes/updatePasteLikesDislikes', async (update: IUpdateLikeDislikePaste, { rejectWithValue }) => {
        try {
            const response = await axios.put<Paste>(PASTE_API_URL + '/' + update.pasteKey + "/likesDislikes", update)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })

export const getPasteCommentsByPasteKey = createAsyncThunk(
    'comments/getPasteCommentsByPasteKey', async (pasteKey: string, { rejectWithValue }) => {
        try {
            const response = await axios.get<PasteComment[]>(PASTE_API_URL + '/' + pasteKey + '/comments')
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })

export const createPasteComment = createAsyncThunk(
    'comments/createPasteComment', async (update: ICreatePasteComment, { rejectWithValue }) => {
        try {
            const response = await axios.post<PasteComment>(PASTE_API_URL + '/' + update.pasteKey + "/comment",
                {
                    parentComment: update.parentComment,
                    comment: update.comment,
                    replyTo: update.replyTo,
                    reCaptchaToken: update.reCaptchaToken
                })
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })

export const updatePasteCommentLikesDislikes = createAsyncThunk(
    'comments/updatePasteCommentLikesDislikes', async (update: IUpdateLikeDislikePasteComment, { rejectWithValue }) => {
        try {
            const response = await axios.put<PasteComment>(PASTE_API_URL + '/' + update.pasteKey + '/comment/' + update.commentId + "/likesDislikes",
                {
                    addLikeCount: update.addLikeCount,
                    addDislikeCount: update.addDislikeCount,
                    reCaptchaToken: update.reCaptchaToken
                })
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    })

export const deletePasteComment = createAsyncThunk(
    'comments/deletePasteComment', async (comment: IDeletePasteComment, { rejectWithValue }) => {
        try {
            const response = await axios.delete<PasteComment>(PASTE_API_URL + '/' + comment.pasteKey + '/comment/' + comment.commentId)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)