import { useState } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import { verifyEmail } from '../core/_authRequests'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { IVerifyEmail } from '../core/_models'
import { toast } from 'react-toastify'

export function VerifyEmail() {
    const [loading, setLoading] = useState(false)

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleReCaptcha = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('verifyEmail')
            return token
        }
    };

    const submitVerification = async (event) => {
        event.preventDefault()
        setLoading(true)

        const token = await handleReCaptcha();

        const params = {
            verificationCode: searchParams.get('code'),
            reCaptchaToken: token
        } as IVerifyEmail

        await verifyEmail(params)
            .then(() => {
                toast.success('Your email address has been verified successfully')
                navigate('/create')
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                toast.error('Error verifying your email address')
            })
    }

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            onSubmit={submitVerification}
        >
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Verify Email Address</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    Verify your Email Address by clicking on the button below
                </div>
                {/* end::Link */}
            </div>

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' className='btn btn-primary me-4'>
                    <span className='indicator-label'>Verify</span>
                    {loading && (
                        <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Form group */}
        </form>
    )
}
