import { FC } from 'react'
import { KTIcon } from '../../../helpers/components/KTIcon'

const SyntaxHighlightGuideCard: FC = () => (
    <div className="card rounded shadow-sm">
        <div className="card-header collapsible cursor-pointer rotate"
            data-bs-toggle="collapse"
            data-bs-target="#ph_syntax_highlight_guide_card"
        >
            <h3 className="card-title">Syntax Highlight</h3>
            <div className="card-toolbar rotate-180">
                <i className="ki-duotone ki-down fs-1"></i>
            </div>
        </div>
        <div id="ph_syntax_highlight_guide_card" className="collapse show">
            <div className="card-body">
                Syntax highlighting can help make code more readable, which can be especially helpful when working on large projects or collaborating with others.
                <br /><br />
                <div>The range of syntax available include popular programming languages such as Java, Go, Python, Rust and many more.</div>
                <br />
                <div>Click on the <KTIcon iconName='setting-3' className='fs-3 text-primary' /> icon to display the syntax selection menu.</div>
            </div>
        </div>
    </div>
)

export { SyntaxHighlightGuideCard }
