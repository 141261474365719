import React from 'react';

const PastesListLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#daf0ff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
  };

  return (
    <div
      style={{ ...styles, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      className="d-flex align-items-center"
    >
      <div className="text-center">
        Processing...
      </div>
      <div className="ms-2 spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export { PastesListLoading };
