import { StandardEditor } from '../editors/StandardEditor';
import { CodeViewer } from '../viewers/CodeViewer';
import { Link } from 'react-router-dom';
import React from 'react';
import { MarkdownViewerWrapper } from '../viewers/MarkdownViewerWrapper';

const CreateEditPasteAndView = (props) => {

    const [showMarkdown, setShowMarkdown] = React.useState<boolean>(true);
    const [showCode, setShowCode] = React.useState<boolean>(false);

    const mode = props.mode ? props.mode : 'create';

    return (
        <>
            <div className="card card-flush rounded">
                <div className="card-header">
                    <div className="card-title">
                        <span className='fw-bolder'>{mode === "create" ? "Create Paste" : "Edit Paste"}</span>
                    </div>
                </div>
                {/* <!--begin::input textarea--> */}
                <StandardEditor
                    content={props.content}
                    setContent={props.setContent}
                    formik={props.formik}
                />
                {/* <!--end::input textarea--> */}

                {/* begin::tabs */}
                <div className='d-flex mx-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x flex-nowrap border-transparent fs-5 fw-bolder text-center justify-content-center'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary ` +
                                    (showMarkdown && 'active')
                                }
                                onClick={() => {
                                    setShowMarkdown(true)
                                    setShowCode(false)
                                }}
                                to=""
                            >
                                Markdown Formatted
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary ` +
                                    (showCode && 'active')
                                }
                                onClick={() => {
                                    setShowMarkdown(false)
                                    setShowCode(true)
                                }}
                                to=""
                            >
                                Syntax Highlighted
                            </Link>
                        </li>
                    </ul>
                </div>
                {/* end::tabs */}

                {/* show code syntax highlighter viewer or the markdown viewer depending on the state of showCode and showMarkdown */}
                {/* begin::viewers */}
                {showCode && !showMarkdown ?
                    <CodeViewer
                        content={props.content}
                        codeSyntax={props.codeSyntax}
                        setCodeSyntax={props.setCodeSyntax}
                        formik={props.formik}
                    />
                    :
                    <MarkdownViewerWrapper
                        content={props.content}
                        formik={props.formik}
                    />
                }
                {/* end::viewers */}
            </div>
        </>
    );
};

export { CreateEditPasteAndView }