import { useEffect, useState } from "react";
import { CodeBlock } from "../components/CodeBlock";
import Skeleton from "react-loading-skeleton";
import { MarkdownViewer } from "./MarkdownViewer";
import { KTIcon } from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { DeletePasteWarningModal } from "../components/DeletePasteWarningModal";
import { useAuth } from "../../auth/core/Auth";
import { Paste } from "../PasteModels";
import clsx from "clsx";

const prismComponents = require("prismjs/components");

type Props = {
    paste: Paste | undefined;
};

const CarouselViewerWrapper = (props: Props) => {
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const content = props.paste?.content || "";
    const title = props.paste?.title || "";
    const pasteKey = props.paste?.pasteKey || "";
    const codeSyntax = props.paste?.syntax || "";
    const createdBy = props.paste?.createdBy || "";

    const [currentCodeSyntax, setCurrentCodeSyntax] = useState<string>(codeSyntax);

    function handleCodeSyntaxChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentCodeSyntax(event.target.value);
    }

    useEffect(() => {
        if (content) {
            setIsLoading(false);
            setCurrentCodeSyntax(codeSyntax);
        }
    }, [pasteKey, codeSyntax]);

    if (isLoading) {
        return <Skeleton count={15} />;
    }

    return (
        <>
            <div className="card card-flush shadow-sm">
                <div className="card-header">
                    <h2 className="card-title">{title}</h2>
                    <div className="card-toolbar d-flex flex-wrap align-items-center">
                        {currentUser?.id === createdBy && (
                            <div className="d-flex flex-row flex-start">
                                <Link
                                    to="edit"
                                    className="btn btn-sm btn-light-warning py-1 px-3 me-3"
                                >
                                    <KTIcon iconName="pencil" className="fs-0" />
                                    Edit
                                </Link>
                                <button
                                    className="btn btn-sm btn-light-danger py-1 px-3 me-3"
                                    onClick={() => setShowDeleteModal(true)}
                                >
                                    <KTIcon iconName="trash-square" className="fs-0" />
                                    Delete
                                </button>
                            </div>
                        )}
                        <div className="d-flex flex-row flex-start py-3">
                            <div className="d-flex align-items-center bg-light-primary rounded ms-3 px-3 py-1 flex-grow-1 flex-sm-grow-0">
                                <label htmlFor="select-syntax" className="me-3 badge badge-primary">
                                    <i className="ki-duotone ki-code w-20px text-white">
                                        <i className="path1"></i>
                                        <i className="path2"></i>
                                        <i className="path3"></i>
                                        <i className="path4"></i>
                                    </i>Syntax
                                </label>
                                <select
                                    id="select-syntax"
                                    className="form-select form-select-solid py-1"
                                    data-control="select2"
                                    data-placeholder="Select an option"
                                    value={currentCodeSyntax}
                                    onChange={handleCodeSyntaxChange}
                                >
                                    <option value="" />
                                    {Object.keys(prismComponents.languages)
                                        .filter(
                                            (v) =>
                                                v !== "meta" &&
                                                v !== "crystal" &&
                                                v !== "javadoc" &&
                                                v !== "racket" &&
                                                v !== "sparql"
                                        )
                                        .sort()
                                        .map((lang) => (
                                            <option key={lang} value={lang}>
                                                {lang.charAt(0).toUpperCase() + lang.slice(1)}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div
                        id="kt_carousel_3_carousel"
                        className="carousel carousel-custom slide"
                        data-bs-interval="false"
                    >
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <span className="fs-4 fw-bold pe-2">Paste Content</span>
                            <ol className="p-0 m-0 carousel-indicators carousel-indicators-bullet carousel-indicators-active-primary">
                                <li
                                    data-bs-target="#kt_carousel_3_carousel"
                                    data-bs-slide-to="0"
                                    className="ms-1 active"
                                ></li>
                                <li
                                    data-bs-target="#kt_carousel_3_carousel"
                                    data-bs-slide-to="1"
                                    className="ms-1"
                                ></li>
                            </ol>
                        </div>
                        <div className="carousel-inner pt-3">
                            <div className={
                                clsx("carousel-item",
                                    codeSyntax.length > 0 ? "" : "active"
                                )}>
                                <MarkdownViewer content={content} />
                            </div>
                            <div className={
                                clsx("carousel-item",
                                    codeSyntax.length > 0 ? "active" : ""
                                )}>
                                <CodeBlock code={content} language={currentCodeSyntax} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeletePasteWarningModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                pasteKey={pasteKey}
            />
        </>
    );
};

export { CarouselViewerWrapper };