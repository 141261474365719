import React, { useEffect, useRef } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { useCountUp } from 'react-countup'

type Props = {
    className: string
    backGroundColor: string
    dislikes: number
    dislikedPaste: boolean
    handleOnClick: () => void
}

const PasteDislikeCard: React.FC<Props> = ({ className, backGroundColor, dislikes, dislikedPaste, handleOnClick }) => {

    const countUpDislikesRef = useRef<HTMLSpanElement>(null);

    const { update } = useCountUp({
        ref: countUpDislikesRef,
        start: 0,
        end: dislikes,
        duration: 3,
    })

    useEffect(() => {
        update(dislikes)
    }, [dislikes])

    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a role='button' onClick={handleOnClick}>
            <div className={`card ${className} theme-dark-bg-body hover-elevate-up shadow-sm parent-hover`} style={{ backgroundColor: backGroundColor }}>
                <div className='card-body d-flex flex-column'>
                    <div className='d-flex flex-column align-items-start'>
                        <div className='text-dark text-hover-primary fw-bolder fs-3'>
                            <div className='d-flex align-items-center'>
                                <KTIcon iconName={'dislike'} className={`fs-3x me-2 ${dislikedPaste && 'text-primary'}`} />
                                <div>Dislikes</div>
                            </div>
                        </div>
                        {/* insert interesting data here */}
                    </div>
                    <div className='pt-3'>
                        {/* begin::Number */}
                        <span ref={countUpDislikesRef} className='text-dark fw-bolder fs-5x me-2 lh-1'></span>
                        {/* end::Number */}
                        {/* begin::Text */}
                        <span className='text-dark fw-bolder fs-6 lh-1'> users disliked this paste</span>
                        {/* end::Text */}
                    </div>
                </div>
            </div>
        </a>
    )
}

export { PasteDislikeCard }
