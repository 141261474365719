import { toAbsoluteUrl } from '../../../_metronic/helpers';

type Props = {
    imageUrl?: string;
    className?: string;
}

const UserProfilePicture: React.FC<Props> = ({ imageUrl, className }) => {
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    return <img
        src={imageUrl ? imageUrl : toAbsoluteUrl('/media/avatars/blank.png')}
        style={{ objectFit: 'cover' }}
        className={className}
        alt="User Profile Picture"
    />

};

export { UserProfilePicture };
