import { FC } from 'react'

type Props = {
  visibility: string
}

const PasteVisibilityCell: FC<Props> = ({ visibility }) => (
  <div className=''>{visibility.charAt(0).toUpperCase() + visibility.slice(1)}</div>
)

export { PasteVisibilityCell }
