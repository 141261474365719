// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { KTIcon } from '../../../_metronic/helpers'

type Props = {
    backGroundColor: string
    title: string
    description: string
    icon: string
}

const FeatureCard: React.FC<Props> = ({ backGroundColor, title, description, icon }) => {
    useEffect(() => {

    }, [])

    return (
        <div className={`card card-xl-stretch mb-xl-10 theme-dark-bg-body card-rounded`} style={{ backgroundColor: backGroundColor }}>
            <div className='card-body d-flex flex-column align-items-center'>
                <div className='text-center'>
                    <KTIcon className='fs-3tx py-3' iconName={icon} />
                </div>
                <div className='text-center'>
                    <span className='text-dark text-hover-primary fw-bolder fs-3'>{title}</span>
                </div>
                <div className='text-center'>
                    <span className='text-dark fs-6'>{description}</span>
                </div>
            </div>
        </div>
    )
}

export { FeatureCard }
