// React Redux Toolkit
import { store } from './app/store'
import { Provider } from 'react-redux'

import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals'
import { CookieBanner } from '@ashumenko/react-gdpr-cookie-banner';

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

setupAxios(axios)


const queryClient = new QueryClient()
const container = document.getElementById('root')

const reCaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string
const ga4MeasurementId = process.env.REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID as string

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}


if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <AuthProvider>
            <GoogleReCaptchaProvider
              reCaptchaKey={reCaptchaKey}
              useRecaptchaNet
            >
              <CookieBanner
                message="We Value Your Privacy"
                policyLink="/privacy"
                showMarketingOption={false}
                showPreferencesOption={false}
                statisticsOptionInitiallyChecked={true}
                showAcceptSelectionButton={true}
                onAcceptStatistics={() => {
                  ReactGA.initialize(ga4MeasurementId)
                }}
                onDeclineStatistics={() => {
                  ReactGA.reset()
                }}
              />
              <AppRoutes />
            </GoogleReCaptchaProvider>
          </AuthProvider>
        </MetronicI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider >
  )
}

reportWebVitals(SendAnalytics);