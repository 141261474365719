import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './core/Auth'

export function Logout() {
  const { logout } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/auth/login')
    logout()
  }, [logout])

  return null
}
