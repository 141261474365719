/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { KTIcon } from '../../../helpers'
import { UserMenu } from '../user-menu/UserMenu'
import { Link } from 'react-router-dom'
import { roleMappings } from '../../../../app/modules/users/_roleMappings'
import { UserProfilePicture } from '../../../../app/modules/profile/UserProfilePicture'

const AsideUserMenu: FC = () => {
  const { currentUser } = useAuth()

  function renderUserName() {
    if (!currentUser) {
      return 'Guest'
    }
    if (currentUser?.firstName || currentUser?.lastName) {
      return `${currentUser?.firstName} ${currentUser?.lastName}`
    }
    return currentUser?.username
  }

  return (
    <>
      <div className='d-flex flex-stack'>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center'>
          {/* begin::Avatar */}
          <div className='symbol symbol-circle symbol-40px'>
            <UserProfilePicture imageUrl={currentUser?.userDisplayPictureUrl} />
          </div>
          {/* end::Avatar */}
          {/* begin::User info */}
          <div className='ms-2'>
            {
              currentUser ?
                <Link to='/account/overview' className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-3'>
                  {renderUserName()}
                </Link> :
                <Link to='/auth/login' className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-3'>
                  Guest
                </Link>
            }
            {/* begin::Show user's role */}
            <div className='text-muted fw-bold d-block fs-7 lh-3'>
              {currentUser?.userRole ? roleMappings.get(currentUser?.userRole) + ' Tier' : 'Basic Tier'}
            </div>
            {/* end::Show user's role */}
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

        {/* begin::User menu */}
        <div className='ms-1'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='false'
            data-kt-menu-placement='top-end'
          >
            <KTIcon iconName='setting-2' className='fs-1' />
          </div>
          <UserMenu />
        </div>
        {/* end::User menu */}
      </div>
    </>
  )
}

export { AsideUserMenu }
