import React, { useEffect, useState } from 'react';
import { Paste } from '../PasteModels';
import { KTIcon } from '../../../../_metronic/helpers';
import { getUser } from '../../users/api/_userRequests';
import { IGetUser } from '../../users/UserModel';
import { useAppDispatch } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

type Props = {
    paste: Paste | undefined;
};

const PasteMetadataCard: React.FC<Props> = ({ paste }) => {

    const visibilityUpperCase = paste?.visibility ? (paste?.visibility.charAt(0).toUpperCase() + paste?.visibility.slice(1)) : null
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [pasteCreatorUsername, setPasteCreatorUsername] = useState<string>('Guest');

    const getPasteCreatorProfile = async (createdBy) => {
        const userReq = {
            userId: createdBy,
        } as IGetUser

        if (createdBy) {
            await dispatch(getUser(userReq)).unwrap()
                .then((res) => {
                    setPasteCreatorUsername(res.username);
                }).catch((error) => {
                    // console.error(error);
                })
        }
    }

    const handleOnClick = () => {
        if (pasteCreatorUsername !== 'Guest') {
            navigate(`/user/${pasteCreatorUsername}/overview`);
        }
    }

    useEffect(() => {
        if (paste?.createdBy && paste?.createdBy.length > 0) {
            getPasteCreatorProfile(paste?.createdBy);
        }
    }, [paste]);

    dayjs.extend(relativeTime);
    dayjs.extend(utc);

    return (
        <div className='d-flex flex-column'>
            <div className='d-flex flex-wrap'>
                {/* begin::Paste Title */}
                <div className='card shadow-sm d-flex flex-row mb-3 flex-fill'>
                    <div className='symbol d-flex align-items-center'>
                        <span className='symbol-label sidebar-bg-muted'>
                            <KTIcon iconName='text' className='fs-2x text-warning' />
                        </span>
                    </div>

                    <div className='d-flex flex-column justify-content-center mx-3'>
                        <div className='text-hover-primary fs-6 fw-bolder'>Title</div>
                        <span className='text-muted fw-bold'>{paste?.title}</span>
                    </div>
                </div>
                {/* end::Paste Title */}
                {/* start::Paste Unique Identifier */}
                <div className='card shadow-sm d-flex flex-row mb-3 flex-fill'>
                    <div className='symbol d-flex align-items-center'>
                        <span className='symbol-label sidebar-bg-muted'>
                            <KTIcon iconName='key' className='fs-2x text-success' />
                        </span>
                    </div>

                    <div className='d-flex flex-column justify-content-center mx-3'>
                        <div className='text-hover-primary fs-6 fw-bolder'>Paste Unique Identifier</div>
                        <span className='text-muted fw-bold'>{paste?.pasteKey}</span>
                    </div>
                </div>
                {/* end::Paste Unique Identifier */}
                {/* start::Paste Creator */}

                {pasteCreatorUsername !== 'Guest' ?
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a role='button' onClick={handleOnClick}>
                        <div className='card hover-elevate-up shadow-sm parent-hover d-flex flex-row mb-3 flex-fill'>
                            <div className='symbol d-flex align-items-center'>
                                <span className='symbol-label sidebar-bg-muted'>
                                    <KTIcon iconName='key' className='fs-2x text-success' />
                                </span>
                            </div>

                            <div className='d-flex flex-column justify-content-center mx-3'>
                                <div className='text-hover-primary fs-6 fw-bolder'>Created By</div>
                                <span className='text-muted fw-bold'>{pasteCreatorUsername}</span>
                            </div>
                        </div>
                    </a>
                    :
                    <div className='card shadow-sm d-flex flex-row mb-3 flex-fill'>
                        <div className='symbol d-flex align-items-center'>
                            <span className='symbol-label sidebar-bg-muted'>
                                <KTIcon iconName='key' className='fs-2x text-success' />
                            </span>
                        </div>

                        <div className='d-flex flex-column justify-content-center mx-3'>
                            <div className='text-hover-primary fs-6 fw-bolder'>Created By</div>
                            <span className='text-muted fw-bold'>{pasteCreatorUsername}</span>
                        </div>
                    </div>
                }
                {/* end::Paste Creator */}

                {/* start::Paste Visibility */}
                <div className='card shadow-sm d-flex flex-row mb-3 flex-fill'>
                    <div className='symbol d-flex align-items-center'>
                        <span className='symbol-label sidebar-bg-muted'>
                            <KTIcon iconName='eye' className='fs-2x text-danger' />
                        </span>
                    </div>

                    <div className='d-flex flex-column justify-content-center mx-3'>
                        <div className='text-hover-primary fs-6 fw-bolder'>Visibility</div>
                        <span className='text-muted fw-bold'>{visibilityUpperCase}</span>
                    </div>
                </div>
                {/* end::Paste Visibility */}
                {/* start::Paste Created Date */}
                <div className='card shadow-sm d-flex flex-row mb-3 flex-fill'>
                    <div className='symbol d-flex align-items-center'>
                        <span className='symbol-label sidebar-bg-muted'>
                            <KTIcon iconName='time' className='fs-2x text-info' />
                        </span>
                    </div>

                    <div className='d-flex flex-column justify-content-center mx-3'>
                        <div className='text-hover-primary fs-6 fw-bolder'>Created Date</div>
                        <span className='text-muted fw-bold'>
                            {dayjs(paste?.createdDate).local().format('dddd, MMMM DD YYYY, h:mm:ss a')}
                        </span>
                    </div>
                </div>
                {/* end::Paste Created Date */}
                {/* start::Paste Expiration Date */}
                <div className='card shadow-sm d-flex flex-row mb-3 flex-fill'>
                    <div className='symbol d-flex align-items-center'>
                        <span className='symbol-label sidebar-bg-muted'>
                            <KTIcon iconName='watch' className='fs-2x text-primary' />
                        </span>
                    </div>

                    <div className='d-flex flex-column justify-content-center mx-3'>
                        <div className='text-hover-primary fs-6 fw-bolder'>Expiration Date</div>
                        <span className='text-muted fw-bold'>
                            {dayjs(paste?.expirationDate).local().format('dddd, MMMM DD YYYY, h:mm:ss a')}
                        </span>
                    </div>
                </div>
                {/* start::Paste Expiration Date */}
            </div>
        </div>
    );
};

export { PasteMetadataCard };