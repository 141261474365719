/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { Link, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { ResetPassword } from './components/ResetPassword'
import { VerifyEmail } from './components/VerifyEmail'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => { }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <Link to="/" className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default-dark.svg')}
            className='theme-dark-show h-45px'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default.svg')}
            className='theme-light-show h-45px'
          ></img>
        </Link>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>
          <Link to="/about" className='text-muted text-hover-primary px-2'>
            About Us
          </Link>
          <Link to="/terms" className='text-muted text-hover-primary px-2'>
            Terms of Use
          </Link>
          <Link to="/privacy" className='text-muted text-hover-primary px-2'>
            Privacy Policy
          </Link>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage: FC = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='registration' element={<Registration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='verify-email' element={<VerifyEmail />} />
        <Route index element={<Login />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { AuthPage }
