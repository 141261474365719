import { useListView } from '../../core/ListViewProvider'
import { PastesListToolbar } from './PastesListToolbar'
import { PastesListGrouping } from './PastesListGrouping'
import { PastesListSearchComponent } from './PastesListSearchComponent'

const PastesListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <PastesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <PastesListGrouping /> : <PastesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { PastesListHeader }
