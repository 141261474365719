import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { Paste } from '../modules/pastes/PasteModels'
import { addNewPaste, deletePasteByPasteKey, getPasteByPasteKey, searchPastesByQuery, updatePaste, updatePasteLikesDislikes } from '../modules/pastes/api/_pasteRequests'

interface PasteState {
    pastes: Paste[]
    status: 'idle' | 'loading' | 'failed' | 'succeeded' | 'fulfilled'
    error?: any
}

const initialState: PasteState = {
    pastes: [],
    status: 'idle',
}

const pastesSlice = createSlice({
    name: 'pastes',
    initialState,
    reducers: {
        pasteAdded(state, action: PayloadAction<Paste>) {
            state.pastes.push(action.payload)
        },
        pasteUpdated(state, action: PayloadAction<Paste>) {
            const { id, title, content, pasteKey, expirationDate, viewCount, likeCount, dislikeCount, likedUsers, dislikedUsers, uploadedFileSizeInBytes, lastFileUploadedTime, uploadedFileType, uploadedFileOriginalName, visibility, syntax, createdBy, createdDate } = action.payload
            const existingPaste = state.pastes.find(paste => paste.id === id)

            if (existingPaste) {
                existingPaste.title = title
                existingPaste.content = content
                existingPaste.pasteKey = pasteKey
                existingPaste.expirationDate = expirationDate
                existingPaste.viewCount = viewCount
                existingPaste.likeCount = likeCount
                existingPaste.dislikeCount = dislikeCount
                existingPaste.likedUsers = likedUsers
                existingPaste.dislikedUsers = dislikedUsers
                existingPaste.uploadedFileSizeInBytes = uploadedFileSizeInBytes
                existingPaste.lastFileUploadedTime = lastFileUploadedTime
                existingPaste.uploadedFileType = uploadedFileType
                existingPaste.uploadedFileOriginalName = uploadedFileOriginalName
                existingPaste.visibility = visibility
                existingPaste.syntax = syntax
                existingPaste.createdBy = createdBy
                existingPaste.createdDate = createdDate
            }
        },
        pasteDeleted(state, action: PayloadAction<Paste>) {
            const { id } = action.payload
            const existingPaste = state.pastes.find(paste => paste.id === id)
            if (existingPaste) {
                state.pastes = state.pastes.filter(paste => paste.id !== id)
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(addNewPaste.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(addNewPaste.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.pastes = state.pastes.concat(action.payload)
            })
            .addCase(addNewPaste.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updatePaste.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updatePaste.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // update the paste to the array
                const existingPaste = state.pastes.find(paste => paste.id === action.payload.id)
                if (existingPaste) {
                    existingPaste.title = action.payload.title
                    existingPaste.content = action.payload.content
                    existingPaste.pasteKey = action.payload.pasteKey
                    existingPaste.expirationDate = action.payload.expirationDate
                    existingPaste.viewCount = action.payload.viewCount
                    existingPaste.likeCount = action.payload.likeCount
                    existingPaste.dislikeCount = action.payload.dislikeCount
                    existingPaste.likedUsers = action.payload.likedUsers
                    existingPaste.dislikedUsers = action.payload.dislikedUsers
                    existingPaste.uploadedFileSizeInBytes = action.payload.uploadedFileSizeInBytes
                    existingPaste.lastFileUploadedTime = action.payload.lastFileUploadedTime
                    existingPaste.uploadedFileType = action.payload.uploadedFileType
                    existingPaste.uploadedFileOriginalName = action.payload.uploadedFileOriginalName
                    existingPaste.visibility = action.payload.visibility
                    existingPaste.syntax = action.payload.syntax
                    existingPaste.createdBy = action.payload.createdBy
                    existingPaste.createdDate = action.payload.createdDate
                }
            })
            .addCase(updatePaste.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(searchPastesByQuery.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(searchPastesByQuery.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.pastes = state.pastes.concat(action.payload.pastes)
            })
            .addCase(searchPastesByQuery.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deletePasteByPasteKey.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(deletePasteByPasteKey.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // delete paste from the array
                state.pastes = state.pastes.filter((paste) => paste.id !== action.payload.id)
            })
            .addCase(deletePasteByPasteKey.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getPasteByPasteKey.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getPasteByPasteKey.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.pastes = state.pastes.concat(action.payload)
            })
            .addCase(getPasteByPasteKey.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updatePasteLikesDislikes.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updatePasteLikesDislikes.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // update the paste to the array
                const existingPaste = state.pastes.find(paste => paste.id === action.payload.id)
                if (existingPaste) {
                    existingPaste.likeCount = action.payload.likeCount
                    existingPaste.dislikeCount = action.payload.dislikeCount
                    existingPaste.likedUsers = action.payload.likedUsers
                    existingPaste.dislikedUsers = action.payload.dislikedUsers
                }
            })
            .addCase(updatePasteLikesDislikes.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }

})

export const { pasteAdded, pasteUpdated, pasteDeleted } = pastesSlice.actions

export const selectAllPastes = (state: { pastes: PasteState }) => state.pastes.pastes

export const selectRecentPastes = (state: { pastes: PasteState }) => state.pastes.pastes.slice(0, 5).sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())

export const selectPasteByPasteKey = (state: { pastes: PasteState }, pasteKey) => state.pastes.pastes.find(paste => paste.pasteKey === pasteKey)

export const selectPastesByQuery = (state: { pastes: PasteState }, query) =>
    state.pastes.pastes.filter(paste =>
        paste.title.toLowerCase().includes(query.toLowerCase()) ||
        paste.content.toLowerCase().includes(query.toLowerCase())
    );

export default pastesSlice.reducer