/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/create'
        icon='black-right'
        // title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        title='Create Paste'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>You</span>
        </div>
      </div>
      <AsideMenuItem
        to='/account/pastes'
        icon='black-right'
        title='Paste Management'
        fontIcon='bi-layers'
      />

      <AsideMenuItemWithSub
        to='/account'
        title='Account'
        icon='black-right'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/account/settings' title='Manage' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* separator start */}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {/* separator end */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>PasteThing</span>
        </div>
      </div>
      <AsideMenuItem to='/about' title='About Us' hasBullet={false} icon='document' />
      <AsideMenuItem to='/faq' title='FAQ' hasBullet={false} icon='message-question' />
      <AsideMenuItem to='/terms' title='Terms of Use' hasBullet={false} icon='book-square' />
      <AsideMenuItem to='/privacy' title='Privacy Policy' hasBullet={false} icon='security-user' />
      <AsideMenuItem to='/abuse' title='Report Abuse' hasBullet={false} icon='flag' />
    </>
  )
}
