import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { SubSuccessPasteCreationCard } from './SubSuccessPasteCreationCard';

const SuccessPasteCreationModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{ "border": "none" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <i className="fas fa-circle-check me-3 fs-3 text-success" />
                    Successfully Created Paste!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='row'>
                <div className="col">
                    <SubSuccessPasteCreationCard
                        qrCodeUrl={props.url}
                        bgColor='light-primary'
                    />
                </div>
            </Modal.Body >
            <Modal.Footer style={{ "border": "none" }}>
                <Link className="btn btn-warning text-black" onClick={props.onHide} to={props.url}><i className="bi bi-arrow-right text-black fs-4 me-2" />View Paste</Link>
            </Modal.Footer>
        </Modal>
    );
}

export { SuccessPasteCreationModal }
