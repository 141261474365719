import { useAuth } from "../../auth/core/Auth";
import { KTIcon } from "../../../../_metronic/helpers/components/KTIcon";
import { useEffect, useState } from "react";
import { IGetUser } from "../../users/UserModel";
import { useAppDispatch, } from "../../../hooks";
import { getUser } from "../../users/api/_userRequests";
import { RedirectToAuthModal } from "../../auth/components/RedirectToAuthModal";
import { ICreatePasteComment } from "../../pastes/PasteModels";
import { createPasteComment, } from "../../pastes/api/_pasteRequests";
import { Link } from "react-router-dom";

import Skeleton from "react-loading-skeleton";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import { UserProfilePicture } from "../../profile/UserProfilePicture";
import { updateDisplayPicture } from "../../../redux/usersSlice";
import { downloadFile } from "../../auth/core/_authRequests";

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';


const SubComment = ({ subComment, handleDeleteCommentOnClick, handleLikeCommentOnClick }) => {
    const { currentUser } = useAuth();
    const [likedSubComment, setLikedSubComment] = useState<boolean>(false)
    const [commenterProfilePic, setCommenterProfilePic] = useState<string | undefined>('');
    const [commenterUsername, setCommenterUsername] = useState<string>('');
    const [replyToUsername, setReplyToUsername] = useState<string>('');
    const [showReplyBox, setShowReplyBox] = useState<boolean>(false);
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
    const [newComment, setNewComment] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch()

    useEffect(() => {

        subComment.likedUsers.includes(currentUser?.id) ? setLikedSubComment(true) : setLikedSubComment(false);
        const createdBy = subComment.createdBy;
        if (createdBy) {
            const userReq = {
                userId: createdBy,
            } as IGetUser
            const replyToReq = {
                userId: subComment.replyTo,
            } as IGetUser

            dispatch(getUser(userReq)).unwrap()
                .then(async (res) => {
                    setCommenterUsername(res.username);

                    if (res.hasDisplayPicture) {
                        const fileKey = 'user-dp/' + res.id + '-dp'
                        setIsLoading(true);
                        await downloadFile(fileKey)
                            .then((arr) => {
                                const url = URL.createObjectURL(arr.data);
                                dispatch(updateDisplayPicture({ id: res.id, userDisplayPictureUrl: url }))
                                setCommenterProfilePic(url);
                            }).catch(() => {
                                // do nothing, fail silently
                            })
                    }
                }).catch((error) => {
                    // console.error(error);
                    setCommenterUsername("Deleted user");
                }).then(() => {
                    dispatch(getUser(replyToReq)).unwrap()
                        .then((res) => {
                            setReplyToUsername(res.username);
                        }).catch((error) => {
                            // console.error(error);
                            setCommenterUsername("Deleted user");
                            setReplyToUsername("Deleted user");
                        })
                }).finally(() => {
                    setIsLoading(false);
                })
        }

    }, [subComment, dispatch])

    const params = {
        pasteKey: subComment.pasteKey,
        comment: newComment,
        parentComment: subComment.parentComment,
        replyTo: subComment.createdBy,
    } as ICreatePasteComment

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptcha = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('createComment')
            return token
        }
    };

    const handleCreateComment = async (event) => {
        event.preventDefault()
        if (!currentUser) {
            setShowLoginModal(true);
            return;
        }

        try {
            const token = await handleReCaptcha();
            params.reCaptchaToken = token ? token : '';

            await dispatch(createPasteComment(params)).unwrap()
            setNewComment('');
            setShowReplyBox(false);
        } catch (err) {
            // console.log(err);
            toast.error("Unable to comment. Please try again later.");

        }

    }

    if (isLoading) {
        return <Skeleton count={5} />;
    }

    dayjs.extend(relativeTime);

    return (
        <div className='row mt-1'>
            {/* begin::User */}
            <Link to={'/user/' + commenterUsername + '/overview'} className='d-flex flex-row align-items-center flex-grow-1'>
                {/* begin::Avatar */}
                <div className='symbol symbol-45px me-5'>
                    <UserProfilePicture imageUrl={commenterProfilePic} />
                </div>
                {/* end::Avatar */}

                {/* begin::Info */}
                <div className='d-flex flex-column'>
                    {/* <a role='button' className='text-gray-800 text-hover-primary fs-6 fw-bold'> */}
                    {commenterUsername}
                    {/* </a> */}
                    <span className='text-gray-400 fw-semibold'>{dayjs(subComment.createdDate).fromNow()}</span>
                </div>
                {/* end::Info */}

            </Link>
            {/* end::User */}

            <div className='my-3 mt-5'>
                {/* begin::Text */}
                <div className='text-gray-800 d-flex align-items-center'>
                    <Link to={'/user/' + replyToUsername + '/overview'} className='mb-1'>
                        <div className='badge badge-light-primary'>
                            <KTIcon iconName='arrow-right' className={`fs-6 text-primary`} />
                            {replyToUsername}
                        </div>
                    </Link>
                    <div className='ms-2'>
                        {subComment.comment}
                    </div>
                </div>
                {/* begin::Toolbar */}
                <div className='d-flex align-items-center mt-3'>
                    <button
                        onClick={() => handleLikeCommentOnClick(subComment.id, likedSubComment).then(() => setLikedSubComment(!likedSubComment))}
                        className={`btn btn-sm btn-light btn-color-muted btn-active-light-${likedSubComment && 'success'} px-4 py-2 me-4`}
                    >
                        <KTIcon iconName='heart' className={`fs-2 ${likedSubComment && 'text-success'}`} />
                        {subComment.likeCount}
                    </button>
                    <button
                        onClick={() => {
                            setShowReplyBox(!showReplyBox)
                        }}
                        className='btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4'
                    >
                        <KTIcon iconName='message-text' className={`fs-2`} />
                        Reply
                    </button>
                    {/* begin::Menu */}
                    {currentUser && subComment.createdBy === currentUser.id &&
                        <div className=''>
                            <button
                                type='button'
                                className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 me-4'
                                onClick={() => handleDeleteCommentOnClick(subComment.id)}
                            >
                                <KTIcon iconName='trash-square' className='fs-2' />
                                Delete
                            </button>
                        </div>
                    }
                    {/* end::Menu */}
                </div>
                {
                    showReplyBox && (
                        <form className="d-flex flex-wrap justify-content-between mt-5" onSubmit={handleCreateComment}>
                            <div className="col-12 col-md-11">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input
                                                autoFocus
                                                className="form-control form-control-solid"
                                                placeholder="Leave a comment"
                                                value={newComment}
                                                onChange={(e) => setNewComment(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-1 mt-2 mt-md-0 d-flex justify-content-md-end">
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit" disabled={!newComment}>
                                        Reply
                                    </button>
                                </div>
                            </div>
                        </form>)
                }
            </div>
            <RedirectToAuthModal
                show={showLoginModal}
                onHide={() => setShowLoginModal(false)}
                title="Attention: Members-Exclusive Paste Zone"
                message="Unlock the feature of private pastes. Don't miss out! Create an account or log in to explore this exclusive feature."
            />
        </div>
    );
}

export { SubComment }