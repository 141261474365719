import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { Paste } from '../pastes/PasteModels';
import { IGetUser } from '../users/UserModel';
import { getUser } from '../users/api/_userRequests';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
    paste: Paste
}

const SearchResult: FC<Props> = ({ paste }) => {
    const [pasteCreatorUsername, setPasteCreatorUsername] = useState<string>('')
    const navigate = useNavigate()
    const getPasteCreatorProfile = async (createdBy) => {
        const userReq = {
            userId: createdBy,
        } as IGetUser

        if (createdBy) {
            await dispatch(getUser(userReq)).unwrap()
                .then((res) => {
                    setPasteCreatorUsername(res.username);
                }).catch((error) => {
                    // console.error(error);
                })
        }
    }

    useEffect(() => {
        getPasteCreatorProfile(paste.createdBy)
    }, [paste])

    const handleClick = () => {
        navigate("/p/" + paste.pasteKey)
    }

    const dispatch = useAppDispatch()

    // hacky way to recolor the `Link` component
    let themeMode = 'light'
    if (localStorage.getItem('kt_theme_mode_value')) {
        themeMode = localStorage.getItem('kt_theme_mode_value') || 'light'
    }

    return (
        <div onClick={handleClick} className={`row py-3 hover-elevate-up ${themeMode === 'light' ? 'text-black' : 'text-white'}`} style={{cursor: 'pointer'}}>
            <div className='col-12'>
                <div className="card card-flush shadow-sm py-0 px-3 card-rounded">
                    <div className="card-header">
                        <h3 className="card-title">{paste.title}</h3>
                        <div className="card-toolbar">
                            <span className="badge badge-light-info mx-3">{paste.viewCount} views</span>
                            <span className="badge badge-light-danger mx-3">{paste.likeCount} likes</span>
                            <span className="badge badge-light-primary mx-3">{paste.dislikeCount} dislikes</span>
                        </div>
                    </div>
                    <div className="card-body py-6 px-6 bg-light card-rounded">
                        {paste.content.substring(0, 500)}...
                    </div>
                    <div className="card-footer text-end text-muted">
                        {pasteCreatorUsername ? <div>Created by: <Link to={"/user/" + pasteCreatorUsername + "/overview"} className="text-dark">{pasteCreatorUsername}</Link></div> : "Created by: Guest"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { SearchResult };