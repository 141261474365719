import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../../_metronic/helpers/components/KTIcon';
import { useAppDispatch } from '../../../hooks';
import { deletePasteByPasteKey } from '../api/_pasteRequests';
import { useNavigate } from 'react-router-dom';

const DeletePasteWarningModal = (props) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const deletePaste = async () => {
        dispatch(deletePasteByPasteKey(props.pasteKey)).unwrap()
            .then((res) => {
                navigate("/create")
            }).catch((error) => {
                // console.log(error);
            })
    }

    return (
        <Modal
            {...props}
            size="md"
            pastekey={props.pasteKey}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton style={{ "border": "none" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <i className="fa-solid fa-trash me-3 fs-3 text-danger" />
                    Delete Paste?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='row'>
                <div className="col">
                    Are you sure you want to delete the paste? This action cannot be undone.
                </div>
            </Modal.Body >
            <Modal.Footer style={{ "border": "none" }}>
                <button className="btn btn-sm btn-light-danger px-3 ml-3" onClick={deletePaste}>
                    <KTIcon iconName="trash-square" className="fs-1" />
                    Delete
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export { DeletePasteWarningModal }
