import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkFrontmatter from 'remark-frontmatter';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';

//TODO: think about how to safely implement autolink to headers
// `rehype-sanitize` breaks the code rendering portion of markdown viewer
// refer to https://github.com/rehypejs/rehype-autolink-headings#security

// import rehypeAutolinkHeadings from 'rehype-autolink-headings';
// import rehypeSlug from 'rehype-slug';
// import rehypeSanitize from 'rehype-sanitize';

import 'katex/dist/katex.min.css';


const MarkdownViewer = (props) => {

    return (
        <div className="card-body scroll">
            {/* <!--begin::markdown viewer--> */}
            <ReactMarkdown
                children={props.content}
                remarkPlugins={[remarkGfm, remarkFrontmatter, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                components={{
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        return !inline && match ? (
                            <SyntaxHighlighter
                                {...props}
                                children={String(children).replace(/\n$/, '')}
                                style={coldarkDark}
                                language={match[1]}
                                PreTag="div"
                            />
                        ) : (
                            <code {...props} className={className}>
                                {children}
                            </code>
                        );
                    },
                }}
            />
            {/* <!--end::markdown viewer--> */}
        </div>
    );
};

export { MarkdownViewer };
