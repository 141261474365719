// refer to the list here - https://github.com/Alcumus/react-doc-viewer#current-renderable-file-types
// a map of file extensions to mime types that can be rendered in the browser
export const renderableFileTypes: Map<string, string> = new Map([
    ['image/jpg', 'jpg'],
    ['image/jpeg', 'jpg'],
    ['image/png', 'png'],
    ['image/gif', 'gif'],
    ['application/pdf', 'pdf'],
    ['text/csv', 'csv'],
]);

export const isRenderableFileType = (mimeType: string): boolean => {
    return renderableFileTypes.has(mimeType);
}