export function handleFormErrors(error: any, setStatus: any) {
  try {
    const errorMessages: string[] = JSON.parse(error.response.data.message)
    const errMsg = errorMessages.join(', ')
    setStatus(errMsg)
  }
  catch (e) {
    setStatus(error.response.data.message)
  }
}

export function handlePasteFormErrors(errors: any, setErrors: React.Dispatch<React.SetStateAction<string[]>>) {
  try {
    const errorMessages: string[] = JSON.parse(errors.message)
    setErrors(errorMessages)
  }
  catch (e) {
    setErrors([errors.message])
  }
}