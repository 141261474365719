import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../../_metronic/helpers';

const RedirectToAuthModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <KTIcon iconName="lock" className="me-3 fs-3 text-danger" />
                    Authentication Required
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{props.title}</h4>
                <p>
                    {props.message}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Link onClick={props.onHide} to="/auth/login">Login</Link>
                <Link onClick={props.onHide} to="/auth/registration">Register</Link>
            </Modal.Footer>
        </Modal>
    );
}

export { RedirectToAuthModal }