/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
    className?: string;
}

const PasteNotFoundCard: React.FC<Props> = ({ className }) => {
    return (
        <div className={`d-flex align-items-center justify-content-center ${className}`}>
            <div className="col-md-6 col-sm-12">
                <div className="card">
                    {/* begin::Header */}
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">Paste Not Found</span>
                            <span className="text-muted fw-semibold fs-7">Perhaps a typo?</span>
                        </h3>
                    </div>
                    {/* end::Header */}

                    {/* begin::Body */}
                    <div className="card-body d-flex flex-column text-center">
                        <div className="">
                            <p className="text-center fs-6 pb-5">
                                Maybe the paste expired. 
                                <br />
                                <span className="badge badge-light-success fs-8">Pssh:</span>&nbsp; Since you are here, why not create a new paste?
                            </p>
                            <Link to="/create" className="btn btn-warning w-100 py-3">
                                Create Paste
                            </Link>
                        </div>
                    </div>
                    {/* end::Body */}
                </div>
            </div>
        </div>
    );
};

export { PasteNotFoundCard };
