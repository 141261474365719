import { FC } from 'react'

type Props = {
  pasteKey?: string
}

const PastePasteKeyCell: FC<Props> = ({ pasteKey }) => (
  <div className='badge badge-light-success fw-bolder'>{pasteKey}</div>
)

export { PastePasteKeyCell }
