import { FC, useEffect, useState } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { ICreatePasteComment, IDeletePasteComment, IUpdateLikeDislikePasteComment, PasteComment } from '../../pastes/PasteModels'
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useAuth } from '../../auth/core/Auth';
import { RedirectToAuthModal } from '../../auth/components/RedirectToAuthModal';
import { createPasteComment, deletePasteComment, updatePasteCommentLikesDislikes } from '../../pastes/api/_pasteRequests';
import { getUser } from '../../users/api/_userRequests';
import { IGetUser } from '../../users/UserModel';
import { selectPasteCommentsByCommentId } from '../../../redux/commentsSlice';
import { SubComment } from './SubComment';
import Skeleton from 'react-loading-skeleton';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateDisplayPicture } from '../../../redux/usersSlice';
import { downloadFile } from '../../auth/core/_authRequests';
import { UserProfilePicture } from '../../profile/UserProfilePicture';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';


type Props = {
    comment: PasteComment
}
const Comment: FC<Props> = ({ comment }) => {
    const { currentUser } = useAuth();
    const [likedComment, setLikedComment] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
    const [commenterProfilePic, setCommenterProfilePic] = useState<string | undefined>('');
    const [commenterUsername, setCommenterUsername] = useState<string>('');
    const [showNestedComments, setShowNestedComments] = useState<boolean>(false);
    const [showReplyForm, setShowReplyForm] = useState<boolean>(false);
    const [newComment, setNewComment] = useState<string>('')

    const dispatch = useAppDispatch()
    const commentSubComments = useAppSelector(state => selectPasteCommentsByCommentId(state, comment.id))

    const currentUserId = currentUser?.id || '';
    useEffect(() => {

        comment.likedUsers.includes(currentUserId) ? setLikedComment(true) : setLikedComment(false);
        const createdBy = comment.createdBy;
        if (createdBy) {
            const userReq = {
                userId: createdBy,
            } as IGetUser

            dispatch(getUser(userReq)).unwrap()
                .then(async (res) => {
                    setCommenterUsername(res.username);

                    if (res.hasDisplayPicture) {
                        const fileKey = 'user-dp/' + res.id + '-dp'
                        setIsLoading(true);
                        await downloadFile(fileKey)
                            .then((arr) => {
                                const url = URL.createObjectURL(arr.data);
                                dispatch(updateDisplayPicture({ id: res.id, userDisplayPictureUrl: url }))
                                setCommenterProfilePic(url);
                            }).catch(() => {
                                // do nothing, fail silently
                            })
                    }
                }).catch((error) => {
                    // console.error(error);
                    setCommenterUsername("Deleted user");
                }).finally(() => {
                    setIsLoading(false);
                })

        }
    }, [comment, currentUserId, dispatch])

    const params = {
        pasteKey: comment.pasteKey,
        comment: newComment,
        parentComment: comment.id,
        replyTo: comment.createdBy,
    } as ICreatePasteComment

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptcha = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('createComment')
            return token
        }
    };

    const handleCreateComment = async (event) => {
        event.preventDefault()
        if (!currentUser) {
            setShowLoginModal(true);
            return;
        }

        try {
            const token = await handleReCaptcha();
            params.reCaptchaToken = token ? token : '';

            await dispatch(createPasteComment(params)).unwrap()
            setNewComment('');
            setShowReplyForm(false);
            setShowNestedComments(true);
        } catch (err) {
            // console.log(err);
            toast.error("Unable to comment. Please try again later.");
        }

    }

    function handleReplyForm(event: { target: { value: React.SetStateAction<string>; }; }) {
        setNewComment(event.target.value)
    }

    const handleLikeCommentOnClick = async (id: string, isLikedComment: React.ComponentState) => {

        if (!currentUser) {
            setShowLoginModal(true);
            return;
        }

        const addLikeComment = isLikedComment ? -1 : 1;
        const updateLikesParams = {
            pasteKey: 'random',
            commentId: id,
            addLikeCount: addLikeComment,
            reCaptchaToken: '',
        } as IUpdateLikeDislikePasteComment

        try {
            const token = await handleReCaptcha();
            updateLikesParams.reCaptchaToken = token ? token : '';

            await dispatch(updatePasteCommentLikesDislikes(updateLikesParams)).unwrap()
        } catch (err) {
            // console.log(err);
        }

    }

    const handleDeleteCommentOnClick = async (id: string) => {
        const deleteParams = {
            pasteKey: 'random',
            commentId: id,
        } as IDeletePasteComment
        await dispatch(deletePasteComment(deleteParams)).unwrap()
            .then((res) => {
                // console.log(res)
            }).catch((error) => {
                // console.log(error);
            });
    }

    const displayNestedComments = () => {
        return commentSubComments.map((subComment) =>
            <SubComment
                key={subComment.id}
                subComment={subComment}
                handleDeleteCommentOnClick={handleDeleteCommentOnClick}
                handleLikeCommentOnClick={handleLikeCommentOnClick}
            />
        )
    }

    if (isLoading) {
        return <Skeleton count={5} />;
    }

    dayjs.extend(relativeTime);

    return (
        <>
            <div className={`card card-px-0`}>
                {/* begin::Body */}
                <div className='card-body pb-0'>
                    {/* begin::Header */}
                    <div className='d-flex align-items-center mb-3'>
                        {/* begin::User */}
                        <Link to={'/user/' + commenterUsername + '/overview'} className='d-flex align-items-center flex-grow-1'>
                            {/* begin::Avatar */}
                            <div className='symbol symbol-45px me-5'>
                                <UserProfilePicture imageUrl={commenterProfilePic} />
                            </div>
                            {/* end::Avatar */}

                            {/* begin::Info */}
                            <div className='d-flex flex-column'>
                                {/* <a role='button' className='text-gray-800 text-hover-primary fs-6 fw-bold'> */}
                                {commenterUsername}
                                {/* </a> */}
                                <span className='text-gray-400 fw-semibold'>{dayjs(comment.createdDate).fromNow()}</span>
                            </div>
                            {/* end::Info */}
                        </Link>
                        {/* end::User */}

                        {/* begin::Menu */}
                        {currentUser && comment.createdBy === currentUser.id &&
                            <div className='my-0'>
                                <button
                                    type='button'
                                    className='btn btn-md btn-icon btn-color-danger btn-active-light-danger'
                                    onClick={() => handleDeleteCommentOnClick(comment.id)}
                                >
                                    <KTIcon iconName='trash-square' className='fs-2hx text-danger' />
                                </button>
                            </div>
                        }
                        {/* end::Menu */}

                    </div>
                    {/* end::Header */}

                    {/* begin::Post */}
                    <div className='my-5'>
                        {/* begin::Text */}
                        <div className='text-gray-800 mb-5'>
                            {comment.comment}
                        </div>
                        {/* end::Text */}

                        {/* begin::Toolbar */}
                        <div className='d-flex align-items-center mb-5'>
                            <button
                                onClick={() => handleLikeCommentOnClick(comment.id, likedComment).then(() => setLikedComment(!likedComment))}
                                className={`btn btn-sm btn-light btn-color-muted btn-active-light-${likedComment && 'success'} px-4 py-2 me-4`}
                            >
                                <KTIcon iconName='heart' className={`fs-2 ${likedComment && 'text-success'}`} />
                                {comment.likeCount}
                            </button>
                            <button
                                onClick={() => {
                                    setShowReplyForm(!showReplyForm)
                                }}
                                className='btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4'
                            >
                                <KTIcon iconName='message-text' className={`fs-2`} />
                                Reply
                            </button>
                            <button
                                onClick={() => setShowNestedComments(!showNestedComments)}
                                className='btn btn-sm btn-light btn-color-muted px-4 py-2 me-4'
                            >
                                <KTIcon iconName={`${showNestedComments ? 'up' : 'down'}`} className={`fs-2`} />
                                {showNestedComments ? 'Hide' : 'Show'} Replies
                            </button>
                        </div>
                        {
                            showReplyForm && (
                                <form className="d-flex flex-wrap justify-content-between mb-3" onSubmit={handleCreateComment}>
                                    <div className="col-12 col-md-11">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        autoFocus
                                                        className="form-control form-control-solid"
                                                        placeholder="Leave a comment"
                                                        value={newComment}
                                                        onChange={handleReplyForm}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-1 mt-2 mt-md-0 d-flex justify-content-md-end">
                                        <div className="form-group">
                                            <button className="btn btn-primary" type="submit" disabled={!newComment}>
                                                Reply
                                            </button>
                                        </div>
                                    </div>
                                </form>)
                        }
                        {/* display subcomments */}
                        <div className='row ms-9'>
                            {showNestedComments ? displayNestedComments() : null}
                        </div>
                        {/* end::Toolbar */}
                    </div>
                    {/* end::Post */}
                </div>
                {/* end::Body */}
            </div>
            <RedirectToAuthModal
                show={showLoginModal}
                onHide={() => setShowLoginModal(false)}
                title="Attention: Members-Exclusive Paste Zone"
                message="Unlock the feature of private pastes. Don't miss out! Create an account or log in to explore this exclusive feature."
            />
        </>
    )
}

export { Comment }
