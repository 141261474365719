/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Highlight, { defaultProps, Prism } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/shadesOfPurple'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const prismComponents = require("prismjs/components");

// Hack to load all languages
// @ts-ignore
(typeof global !== "undefined" ? global : window).Prism = Prism;
try {
  // meta doesn't exist in the prismjs package and thus will *FAIL* because it's a FAILURE
  Object.keys(prismComponents.languages)
  .filter(v => v !== "meta")
  .filter(v => v !== "crystal")
  .filter(v => v !== "javadoc")
  .filter(v => v !== "racket")
  .filter(v => v !== "sparql")
    .forEach((lang) => require("prismjs/components/prism-" + lang));

} catch (e) {
  // this is here in case prismjs ever removes a language, so we can easily debug
  // console.log(e);
}


type Props = {
  code: string
  language: string
}

const CodeBlock: React.FC<Props> = ({ code, language }) => {
  const codeRef = useRef<HTMLDivElement | null>(null)
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (!copied) {
      return
    }

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }, [copied])

  useEffect(() => {
    if (!codeRef.current) {
      return
    }

    const prismCodeElement = codeRef.current.querySelector('.prism-code ') as HTMLDivElement
    if (prismCodeElement) {
      prismCodeElement.style.background = 'none'
      prismCodeElement.style.fontSize = '13px'
    }
  }, [])

  return (
    <div className='py-5'>
      <div className='highlight'>
        <OverlayTrigger
          key='copy-to-clipboard'
          placement='top'
          overlay={<Tooltip id='tooltip-copy-to-clipboard'>Copy Code</Tooltip>}
        >
          <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
            <a className='highlight-copy btn'>{copied ? 'copied' : 'copy'}</a>
          </CopyToClipboard>
        </OverlayTrigger>

        <div className='highlight-code' ref={codeRef}>
          {/* @ts-ignore */}
          <Highlight Prism={Prism} {...defaultProps} theme={theme} code={code} language={language}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => {
              return (
                <pre className={className} style={{ maxHeight: '6666px', ...style }}>
                  {tokens.map((line, i) => (
                    <div {...getLineProps({ line, key: i })}>
                      {line.map((token, key) => (
                        <span {...getTokenProps({ token, key })} />
                      ))}
                    </div>
                  ))}
                </pre>
              )
            }}
          </Highlight>
        </div>
      </div>
    </div>
  )
}

export { CodeBlock }
