import { FC, useEffect, useState } from "react";
import { downloadFile } from "../../auth/core/_authRequests";
import { Paste } from "../PasteModels";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { PastesListLoading } from "../management/components/loading/PastesListLoading";
import { toast } from "react-toastify";


type Props = {
    paste: Paste | undefined;
}

const DynamicFileViewer: FC<Props> = ({ paste }) => {

    const [fileAttachment, setFileAttachment] = useState<File | undefined>(undefined);
    const [fileContent, setFileContent] = useState<string | undefined>(undefined);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const downloadFileAttachment = async () => {
            const pasteId = paste?.id || "";
            const fileKey = 'attachments/' + pasteId + "-thing"
            await downloadFile(fileKey)
                .then((downloadedBlob) => {
                    const file = new File([downloadedBlob.data], fileKey);
                    setFileAttachment(file);

                    // Convert file to base64-encoded string
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        setFileContent(reader.result as string);
                    };
                }).catch((error) => {
                    toast.error("Failed to download file attachment. Please refresh the page.", {
                        autoClose: 2100,
                    });
                }).finally(() => {
                    setIsLoading(false);
                });
        }

        //download file
        if (paste) {
            downloadFileAttachment();
        }
    }, [paste]);

    return (
        <div className="card-body scroll d-flex flex-column">
            {isLoading && <PastesListLoading />}
            {fileAttachment && (
                <DocViewer
                    className="rounded"
                    language="en"
                    pluginRenderers={DocViewerRenderers}
                    documents={[
                        {
                            uri: URL.createObjectURL(fileAttachment),
                            fileType: paste?.uploadedFileType,
                            fileName: paste?.uploadedFileOriginalName,
                        }
                    ]}
                    config={{
                        header: {
                            disableFileName: true,
                            disableHeader: true,
                        },
                        noRenderer: {
                            overrideComponent: () => <div className="text-center mx-auto">Unable to render a preview for this file.</div>,
                        }
                    }}
                    theme={{
                        primary: "#ffd600",
                        secondary: "#ffffff",
                        tertiary: "#00000099",
                        textPrimary: "#ffffff",
                        textSecondary: "#ffd600",
                        textTertiary: "#00000099",
                        disableThemeScrollbar: false,
                    }}
                />
            )}
        </div >
    );
};

export { DynamicFileViewer };