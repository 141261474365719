import React from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import CountUp from 'react-countup'

type Props = {
    className: string
    backGroundColor: string
    views: number
}

const PasteViewCard: React.FC<Props> = ({ className, backGroundColor, views }) => {

    return (
        <div className={`card ${className} theme-dark-bg-body`} style={{ backgroundColor: backGroundColor }}>
            <div className='card-body d-flex flex-column'>
                <div className='d-flex flex-column align-items-start'>
                    <div className='text-dark text-hover-warning fw-bolder fs-3'>
                        <div className='d-flex align-items-center'>
                            <KTIcon iconName={'eye'} className='fs-3x me-2' />
                            <div>Views</div>
                        </div>
                    </div>
                    {/* insert interesting data here */}
                </div>
                <div className='pt-3'>
                    {/* begin::Number */}
                    <span className='text-dark fw-bolder fs-5x me-2 lh-1 '><CountUp duration={3.33} end={views} /></span>
                    {/* end::Number */}
                    {/* begin::Text */}
                    <span className='text-dark fw-bolder fs-6 lh-1'> lifetime views on this paste</span>
                    {/* end::Text */}
                </div>
            </div>
        </div>

    )
}

export { PasteViewCard }
