/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useAuth } from '../auth/core/Auth'
import { getCountryName } from './accountHelper'
import { UserProfilePicture } from '../profile/UserProfilePicture'
import { downloadFile, uploadFile } from '../auth/core/_authRequests'
import { toast } from 'react-toastify'
import { IUploadFile } from '../auth'

const AccountHeader: React.FC = () => {
  const location = useLocation()
  const { currentUser, setCurrentUser } = useAuth()
  const [loadingDp, setLoadingDp] = React.useState(false)

  const calculateProfileCompletion = () => {
    let completed = 0
    if (currentUser?.firstName) completed += 20
    if (currentUser?.lastName) completed += 20
    if (currentUser?.countryCode) completed += 20
    if (currentUser?.username) completed += 20
    if (currentUser?.emailAddress) completed += 20
    return completed
  }

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // file name will be '[userId]-dp'
      const fileName = currentUser?.id + '-dp'
      setLoadingDp(true);

      const uploadForm = {
        file: file,
        fileType: file.type,
        fileName: fileName,
        id: currentUser?.id,
        type: 'dp'
    } as IUploadFile

      uploadFile(uploadForm)
        .then(async () => {
          const fileKey = 'user-dp/' + fileName
          setLoadingDp(true);
          await downloadFile(fileKey)
            .then((arr) => {
              const url = URL.createObjectURL(arr.data);
              if (currentUser) {
                setCurrentUser({ ...currentUser, userDisplayPictureUrl: url });
              }
            }).catch((err) => {
              // fail silently
            }).finally(() => {
              setLoadingDp(false);
            });
        }).catch((err) => {
          toast.error(err.response.data.message);
        }).finally(() => {
          setLoadingDp(false);
        });
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            {loadingDp ?
              <span className='spinner-border spinner-border-sm align-middle ms-2 fs-2x'></span>
              :
              (<div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <UserProfilePicture imageUrl={currentUser?.userDisplayPictureUrl} />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 h-20px w-20px'>
                  <label htmlFor='dp' style={{ cursor: 'pointer' }}>
                    <KTIcon iconName='pencil' className='fs-1 rounded' />
                    <input type='file' name='dp' id='dp' accept="image/*" onChange={handleImage} style={{ display: 'none' }} />
                  </label>
                </div>
              </div>)
            }
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {currentUser?.firstName ? currentUser.firstName : ''}
                  </span>
                  <span>
                    {currentUser?.isEmailVerified ? <KTIcon iconName='verify' className='fs-1 text-primary' /> : ''}
                  </span>
                  {/* <a
                    href='#'
                    className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_upgrade_plan'
                  >
                    Upgrade to Pro
                  </a> */}
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {currentUser?.username}
                  </span>
                  <span
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    {getCountryName(currentUser?.countryCode || '')}
                  </span>
                  <span
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {currentUser?.emailAddress}
                  </span>
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              {/* <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>$4500</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Pastes</div>
                  </div>
                </div>
              </div> */}

              {/* assumes there are 5 fields to set in account settings
              1. firstName
              2. lastName
              3. email address
              4. country
              5. username */}

              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Completion</span>
                  <span className='fw-bolder fs-6'>{calculateProfileCompletion()}%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{ width: calculateProfileCompletion() + '%' }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/account/overview' && 'active')
                }
                to='/account/overview'
              >
                Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/account/settings' && 'active')
                }
                to='/account/settings'
              >
                Settings
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/account/pastes' && 'active')
                }
                to='/account/pastes'
              >
                Pastes
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { AccountHeader }
