/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { downloadFile, getMeUser, login } from '../core/_authRequests'
import { useAuth } from '../core/Auth'
import { handleFormErrors } from '../../errors/errorHelpers'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Minimum 3 character')
    .max(66, 'Maximum 66 characters')
    .matches(/^[a-zA-Z0-9._-]{3,}$/, 'Cannot contain special characters or spaces')
    .required('Valid username is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 character')
    .max(66, 'Maximum 66 characters')
    .required('Valid password is required'),
})

const initialValues = {
  username: '',
  password: '',
  reCaptchaToken: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptcha = async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha('login')
      return token
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const token = await handleReCaptcha();
      values.reCaptchaToken = token ? token : '';

      setLoading(true)
      try {
        const { data: auth } = await login(values.username, values.password, values.reCaptchaToken)
        saveAuth(auth)
        const { data: user } = await getMeUser(values.username)

        if (user.hasDisplayPicture) {
          // download profile picture before navigating to create page
          const fileKey = 'user-dp/' + user?.id + '-dp';
          await downloadFile(fileKey)
            .then((arr) => {
              const url = URL.createObjectURL(arr.data);
              user.userDisplayPictureUrl = url;
            }).catch((err) => {
              // fail silently
            }).finally(() => {
              setCurrentUser(user)
            });
        }
        navigate('/create')
      } catch (error: any) {
        saveAuth(undefined)
        handleFormErrors(error, setStatus)
        if (!error.response.data.message && error.response.status === 403) {
          setStatus('Invalid account')
        }
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Sign in to enjoy the privileges of a PasteThing user.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.username && formik.errors.username },
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='username'
          name='username'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          placeholder='P@assword'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  )
}
