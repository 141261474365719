import { FC, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../hooks';
import { createPasteComment, getPasteCommentsByPasteKey } from '../pastes/api/_pasteRequests';
import { ICreatePasteComment, PasteComment, } from '../pastes/PasteModels';
import { Comment } from './components/Comment';
import { selectPasteCommentsByPasteKey } from '../../redux/commentsSlice';
import { RedirectToAuthModal } from '../auth/components/RedirectToAuthModal';
import { useAuth } from '../auth/core/Auth';
import Skeleton from 'react-loading-skeleton';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type Props = {
    pasteKey: string | undefined
}

const CommentsWrapper: FC<Props> = ({ pasteKey }) => {
    const { currentUser } = useAuth();
    const [newComment, setNewComment] = useState<string>('')

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
    const commentsStatus = useAppSelector(state => state.comments.status)
    const allComments: PasteComment[] = useAppSelector(state => selectPasteCommentsByPasteKey(state, pasteKey || ''))
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (commentsStatus === 'idle') {
            fetchAllComments()
        }
        if (allComments) {
            setIsLoading(false);
        }
    }, [pasteKey, commentsStatus])

    const params = {
        pasteKey: pasteKey,
        comment: newComment,
        parentComment: '0',
        replyTo: '0',
        reCaptchaToken: '',
    } as ICreatePasteComment

    const fetchAllComments = async () => {
        if (pasteKey) {
            await dispatch(getPasteCommentsByPasteKey(pasteKey)).unwrap()
                .then((res) => {
                }).catch((error) => {
                    // console.log(error);
                });
        }
    }

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptcha = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('createComment')
            return token
        }
    };

    const handleCreateComment = async (event) => {
        event.preventDefault()
        if (!currentUser) {
            setShowLoginModal(true);
            return;
        }

        try {
            const token = await handleReCaptcha();
            params.reCaptchaToken = token ? token : '';

            await dispatch(createPasteComment(params)).unwrap()
            setNewComment('');
        } catch (err) {
            console.log(err);
        }

    }

    if (isLoading) {
        return <Skeleton count={15} />;
    }

    return (
        <>
            <div className="card card-flush shadow-sm">
                <div className="card-header">
                    <h3 className="card-title border-0">Comments</h3>
                </div>
                <div className="card-body py-0">
                    <form className="d-flex flex-wrap justify-content-between" onSubmit={handleCreateComment}>
                        <div className="col-12 col-md-11">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input
                                            className="form-control form-control-solid"
                                            placeholder="Leave a comment"
                                            value={newComment}
                                            onChange={(e) => setNewComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-1 mt-2 mt-md-0 d-flex justify-content-md-end">
                            <div className="form-group">
                                <button className="btn btn-primary" type="submit" disabled={!newComment}>
                                    Reply
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="row py-3">
                        {!pasteKey ? (
                            <Skeleton count={15} />
                        ) : (
                            // filter the root level comments
                            allComments.filter(comment => comment.parentComment === "0")
                                .flatMap((comment) => {
                                    return <Comment key={comment.id} comment={comment} />
                                })
                        )}
                    </div>
                </div>
            </div>
            <RedirectToAuthModal
                show={showLoginModal}
                onHide={() => setShowLoginModal(false)}
                title="Attention: Members-Exclusive Paste Zone"
                message="Unlock the feature of private pastes. Don't miss out! Create an account or log in to explore this exclusive feature."
            />
        </>
    )
}

export { CommentsWrapper }
