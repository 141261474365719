import React, { useEffect } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { Paste } from '../PasteModels';
import { downloadFile } from '../../auth/core/_authRequests';
import { toast } from 'react-toastify';


type Props = {
    paste: Paste | undefined;
}


const PasteThingInfoCard: React.FC<Props> = ({ paste }) => {
    const [isDownloading, setIsDownloading] = React.useState(false);

    const handleOnClick = async () => {
        const pasteId = paste?.id || "";
        const fileKey = 'attachments/' + pasteId + "-thing"
        setIsDownloading(true);
        try {
            const downloadedBlob = await downloadFile(fileKey);
            const file = new File([downloadedBlob.data], paste?.uploadedFileOriginalName || fileKey, { type: paste?.uploadedFileType });
            const url = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            toast.error("Failed to download file attachment. Please try again.", {
                autoClose: 2100,
            });
        } finally {
            setIsDownloading(false);
        }
    }

    useEffect(() => {

    }, [paste])

    return (
        <>
            {
                isDownloading ?
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                        <div className="spinner-border align-self-center" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    (<a role='button' onClick={handleOnClick}>
                        <div className={`card hover-elevate-up shadow-sm parent-hover`}>
                            <div className='card-body d-flex flex-column'>
                                <div className='d-flex flex-row align-items-center'>
                                    <KTIcon iconName='file-down' className='fs-3x' />
                                    <div className='ml-3 px-3'>
                                        <div className='d-flex flex-row'>
                                            <ol className="breadcrumb breadcrumb-dot text-muted fs-6 fw-semibold">
                                                <li className="breadcrumb-item">{paste?.uploadedFileOriginalName}</li>
                                                <li className="breadcrumb-item">{paste?.uploadedFileType}</li>
                                                <li className="breadcrumb-item">{paste?.uploadedFileSizeInBytes ? `${(paste?.uploadedFileSizeInBytes / (1024 * 1024)).toFixed(2)}MB` : null}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>)
            }
        </>
    )
}

export { PasteThingInfoCard }
